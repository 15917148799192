import { PmaUsProfile, PmaUsResult } from "../../../../Api/Types/CapacityBooster";
import { Cmts } from "../../../../Api/Types/Config";
import { MacDomain, UsChannel } from "../../../../Api/Types/Topology";
import { ALLOWED_IUCS, BITLOADING_TO_HARMONIC_US_MODULATION, INDENT, PilotPattern } from "./Common";
import { BigIntReplacer } from "../../../../Utils/Converters";

export function ConstructHarmonicUsInstructions(
    t_no_escape: (key: string, options?: any) => string,
    cmts: Cmts | null,
    macDomain: MacDomain | null,
    usChannel: UsChannel | null,
    pmaUsResult: PmaUsResult | null,
    ofdmModProfId: string,
    includeSafetyChecks: boolean,
    includePmaUsResult: boolean
): string {
    const HARMONIC_US_IFDESCR_RE = /ofdma-channel(?<interface_port>\d+:\d+\/\d+)\/(?<ofdma_channel>\d+)/;

    const HARMONIC_US_UNUSED_PROFILE_BITLOADING = 10;


    const resultsLines: string[] = [];
    resultsLines.push(`# ${t_no_escape("deploy.commands.instructions_title")}`);
    if (pmaUsResult == null || !pmaUsResult.calculated_profiles) {
        resultsLines.push("");
        resultsLines.push(`# ${t_no_escape("deploy.commands.no_profile_data")}`);
        return resultsLines.join("\n");
    }

    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_cmts_mac_domain_us_channel", {
        cmts: pmaUsResult.cmts_name,
        mac_domain: pmaUsResult.md_ifdescr,
        us_channel: pmaUsResult.us_ifdescr
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_pma_task_id_us_result_id", {
        pma_task_id: pmaUsResult.task,
        pma_us_result_id: pmaUsResult.id
    })}`);

    let timestamp = pmaUsResult.result_completed_timestamp;
    timestamp = timestamp.replace("T", " ").replace("Z", " UTC");
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.profiles_calculated_at_timestamp", {
        timestamp: timestamp
    })}`);

    // get cmts-specific ids
    let us_chan_info = pmaUsResult.us_ifdescr.match(HARMONIC_US_IFDESCR_RE)?.groups;
    if (!us_chan_info) {
        us_chan_info = {
            interface_port: t_no_escape("deploy.commands.harmonic.unknown_us_cable_interface_port"),
            ofdma_channel: t_no_escape("deploy.commands.harmonic.unknown_us_ofdma_channel"),
        };
    }
    const interfacePort = us_chan_info.interface_port;
    const ofdmaChannel = us_chan_info.ofdma_channel;

    const iucToPmaUsProfile: { [p: number]: PmaUsProfile | null } = {};
    ALLOWED_IUCS.forEach(iuc => {
        iucToPmaUsProfile[iuc] = null;
    });
    pmaUsResult.calculated_profiles.forEach(pmaUsProfile => {
        iucToPmaUsProfile[pmaUsProfile.data_iuc] = pmaUsProfile;
    });

    // determine values for unused IUCs
    const unusedProfileModulation = BITLOADING_TO_HARMONIC_US_MODULATION[HARMONIC_US_UNUSED_PROFILE_BITLOADING];
    let unusedProfilePilotPattern: number | null = null
    ALLOWED_IUCS.forEach(iuc => {  // use pilot pattern from fastest IUC
        const pmaUsProfile = iucToPmaUsProfile[iuc];
        if (pmaUsProfile) {
            unusedProfilePilotPattern = pmaUsProfile.default_pilot_pattern
        }
    });

    if (includeSafetyChecks) {

        resultsLines.push("");
        resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.safety_check_us_rf_port_intro", {
            interfacePort: interfacePort
        })}`);
        resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.safety_check_us_rf_port_do_command")}`);
        resultsLines.push(`show running-config cable us-rf-port ${interfacePort}`);
        resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.safety_check_us_rf_port_check", {
            interfacePort: interfacePort
        })}`);

        resultsLines.push("");
        resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.safety_check_upstream_channel_intro", {
            ofdmaChannel: ofdmaChannel,
            ofdma_mod_prof_id: ofdmModProfId
        })}`);
        resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.safety_check_upstream_channel_do_command")}`);
        resultsLines.push(`show running-config cable us-rf-port ${interfacePort} ofdma-channel ${ofdmaChannel}`);
        resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.safety_check_upstream_channel_check", {
            ofdmaChannel: ofdmaChannel
        })}`);
        resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.safety_check_upstream_channel_check_id", {
            ofdmaChannel: ofdmaChannel,
            ofdma_mod_prof_id: ofdmModProfId
        })}`);

        resultsLines.push("");
        resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.safety_check_mod_profile_intro", {
            ofdma_mod_prof_id: ofdmModProfId            
        })}`)
        resultsLines.push(`show running-config | include "modulation-profile   ${ofdmModProfId}"`);
        resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.safety_check_mod_profile_check_line")}`);

    }

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.begin_configuration_heading")}`);
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.configuration_mode")}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.configuration_mode_do_command")}`);
    resultsLines.push(`config`)

    resultsLines.push("")
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.define_default_modulations_heading")}`);
    // switch to mod-prof-ofdma to set the default modulations
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.move_to_global_modulation", {
        ofdma_mod_prof_id: ofdmModProfId            
    })}`)
    resultsLines.push(`cable mod-prof-ofdma ${ofdmModProfId}`)

    ALLOWED_IUCS.forEach((iuc) => {
        const pmaUsProfile = iucToPmaUsProfile[iuc]
        if (pmaUsProfile) {
            resultsLines.push(`# ${INDENT}${t_no_escape(
                "deploy.commands.harmonic.configure_data_iuc_default_bit_loading_default_pilot_pattern",
                {
                  iuc: iuc,
                  default_bit_loading: pmaUsProfile.default_bit_loading,
                  default_pilot_pattern: pmaUsProfile.default_pilot_pattern
                })}`);
            
            const modulation = BITLOADING_TO_HARMONIC_US_MODULATION[pmaUsProfile.default_bit_loading]
            const pilotPattern = pmaUsProfile.default_pilot_pattern
            resultsLines.push(`data-iuc ${iuc} modulation ${modulation} pilot-pattern ${pilotPattern}`);
        } else {
            resultsLines.push(`# ${INDENT}${t_no_escape(
                "deploy.commands.harmonic.configure_data_iuc_unused",
                {iuc: iuc})}`);
            
            resultsLines.push(`data-iuc ${iuc} modulation ${unusedProfileModulation} pilot-pattern ${unusedProfilePilotPattern}`);
        }

    })

    // define exception zones
    resultsLines.push("")
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.define_exception_zone_heading")}`);
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.move_to_ofdma_channel" , {
        ofdmaChannel: ofdmaChannel,
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.move_to_ofdma_channel_do_command")}`);
    resultsLines.push(`cable us-rf-port ${interfacePort} ofdma-channel ${ofdmaChannel}`)
    ALLOWED_IUCS.forEach((iuc) => {
        const pmaUsProfile = iucToPmaUsProfile[iuc];
        if (!pmaUsProfile) {
            resultsLines.push(`# ${INDENT}${t_no_escape(
                "deploy.commands.harmonic.configure_data_iuc_unused",
                {iuc: iuc})}`);
            resultsLines.push(`no data-iuc ${iuc}`)
            return
        }
        if (pmaUsProfile.us_subcarrier_statuses.length > 1) {
            resultsLines.push(`# ${INDENT}${t_no_escape(
                "deploy.commands.harmonic.configure_exception_zone_data_iuc_bit_loading_pilot_pattern",
                {
                  iuc: iuc,
                  bit_loading: pmaUsProfile.default_bit_loading,
                  pilot_pattern: pmaUsProfile.default_pilot_pattern
                })}`);
            resultsLines.push(`no data-iuc ${iuc}`)
            pmaUsProfile.us_subcarrier_statuses.forEach(pmaUsSubcarrierStatus => {
                if (pmaUsSubcarrierStatus.minislot_bit_loading == pmaUsProfile.default_bit_loading) {
                  return;
                }
                
                const modulation = BITLOADING_TO_HARMONIC_US_MODULATION[pmaUsSubcarrierStatus.minislot_bit_loading];
                const pilotPattern = pmaUsSubcarrierStatus.minislot_pilot_pattern;
                const start_frequency_mhz = pmaUsSubcarrierStatus.start_frequency_hz / 10 ** 6;
                const end_frequency_mhz = pmaUsSubcarrierStatus.end_frequency_hz / 10 ** 6;
                resultsLines.push(
                    `data-iuc ${iuc} band-mhz ${start_frequency_mhz} ${end_frequency_mhz}`
                    + ` modulation ${modulation} pilot-pattern ${pilotPattern}"`
                );
            })

        } else {
            resultsLines.push(`# ${INDENT}${t_no_escape(
              "deploy.commands.harmonic.configure_data_iuc_default_bit_loading_default_pilot_pattern_no_exceptions",
              {
                iuc: iuc,
                default_bit_loading: pmaUsProfile.default_bit_loading,
                default_pilot_pattern: pmaUsProfile.default_pilot_pattern
              })}`);
            resultsLines.push(`no data-iuc ${iuc}`)
          }

    })

    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.commit_configurations")}`)
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.commit_configurations_do_command")}`)
    resultsLines.push(`commit`)
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.safety_check_commit_configurations")}`)    
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.end_configuration_mode")}`)
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.harmonic.end_configuration_mode_do_command")}`)
    resultsLines.push(`end`)

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.post_configuration_complete_check", {
        ofdmaChannel: ofdmaChannel,
        ofdma_mod_prof_id: ofdmModProfId
    })}`);
    resultsLines.push(`# ${INDENT}Execute the following command to check "ofdma-channel ${ofdmaChannel}": `)
    resultsLines.push(`show running-config cable us-rf-port ${interfacePort} ofdma-channel ${ofdmaChannel}`);

    resultsLines.push(`# ${INDENT}Execute the following command to check default modulation "mod-prof-ofdma ${ofdmModProfId}": `)
    resultsLines.push(`show running-config cable mod-prof-ofdma ${ofdmModProfId}`);

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.harmonic.configuration_complete_heading")}`);
    // output debug info if requested
    if (includePmaUsResult) {
        resultsLines.push("");
        resultsLines.push(`# pmaUsResult: ${JSON.stringify(pmaUsResult, BigIntReplacer)}`);
    }
    return resultsLines.join("\n");
}
