type optionalNumber = number | bigint | undefined | null;
type optionalString = string | undefined | null;
type optionalBoolean = boolean | undefined | null;

export const ApiRoutes = {
  auth: {
    login: "/api-v1/users/auth/login/",
    logout: "/api-v1/users/auth/logout/",
    user_info: "/api-v1/users/auth/userinfo/",
    refresh_token: "/api-v1/users/auth/token/refresh/"
  },
  users: {
    users: {
      all: `/api-v1/users/user/`,
      single: (id: number | bigint | undefined): string => `/api-v1/users/user/${id}/`,
      filter: (filters: {
        offset?: optionalNumber,
        limit?: optionalNumber,
      }): string => {
        const {offset, limit} = filters;
        const searchParams = new URLSearchParams();
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        return `/api-v1/users/user/?${searchParams.toString()}`;
      }
    },
  },
  analytics: {
    cmts_details: {
      all: `/api-v1/analytics/cmts-details/`,
      filter: (filters: {
        cmtsId?: optionalNumber,
        regions?: optionalNumber[]
        offset?: optionalNumber,
        limit?: optionalNumber,
      }): string => {
        const {cmtsId, regions, offset, limit} = filters;
        const searchParams = new URLSearchParams();
        if (cmtsId) {
          searchParams.append('cmts', Number(cmtsId).toString());
        }
        if (regions) {
          searchParams.append('regions', regions.join());
        }
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        return `/api-v1/analytics/cmts-details/?${searchParams.toString()}`;
      }
    },
    numberOfCmtsValidCapture: {
      all: `/api-v1/analytics/number-of-cmts-with-valid-captures/`,
      filter: (filters: { regions?: optionalNumber[] }): string => {
        const {regions} = filters;
        const searchParams = new URLSearchParams();
        if (regions) {
          searchParams.append('regions', regions.join());
        }
        return `/api-v1/analytics/number-of-cmts-with-valid-captures/?${searchParams.toString()}`;
      }
    },
    dsPercentTrafficOnProfileZero: {
      all: `/api-v1/analytics/ds-percent-traffic-on-profile-zero/`,
      filter: (filters: { regions?: optionalNumber[] }): string => {
        const {regions} = filters;
        const searchParams = new URLSearchParams();
        if (regions) {
          searchParams.append('regions', regions.join());
        }
        return `/api-v1/analytics/ds-percent-traffic-on-profile-zero/?${searchParams.toString()}`;
      }
    },
    dsCapacityGained: {
      all: `/api-v1/analytics/ds-capacity-gained/`,
      filter: (filters: { regions?: optionalNumber[] }): string => {
        const {regions} = filters;
        const searchParams = new URLSearchParams();
        if (regions) {
          searchParams.append('regions', regions.join());
        }
        return `/api-v1/analytics/ds-capacity-gained/?${searchParams.toString()}`;
      }
    },
    usPercentTrafficOnProfileIuc13: {
      all: `/api-v1/analytics/us-percent-traffic-on-profile-iuc13/`,
      filter: (filters: { regions?: optionalNumber[] }): string => {
        const {regions} = filters;
        const searchParams = new URLSearchParams();
        if (regions) {
          searchParams.append('regions', regions.join());
        }
        return `/api-v1/analytics/us-percent-traffic-on-profile-iuc13/?${searchParams.toString()}`;
      }
    },
    usCapacityGained: {
      all: `/api-v1/analytics/us-capacity-gained/`,
      filter: (filters: { regions?: optionalNumber[] }): string => {
        const {regions} = filters;
        const searchParams = new URLSearchParams();
        if (regions) {
          searchParams.append('regions', regions.join());
        }
        return `/api-v1/analytics/us-capacity-gained/?${searchParams.toString()}`;
      }
    },
  },
  config: {
    calcMaxProfiles: {
      all: '/api-v1/config/hierarchical-config/calc-max-profiles/',
      update: `/api-v1/config/hierarchical-config/calc-max-profiles/`,
      single: (id: number | bigint | undefined): string => `/api-v1/config/hierarchical-config/calc-max-profiles/${id}/`,
      filter: (filters: {
        tenantId: number,
        channelDirection: string,
        cmtsId?: optionalNumber,
      }): string => {
        const {tenantId, channelDirection, cmtsId} = filters;
        const searchParams = new URLSearchParams();
        if (tenantId) {
          searchParams.append("tenant", Number(tenantId).toString());
        }
        if (channelDirection) {
          searchParams.append("channel_direction", channelDirection);
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        return `/api-v1/config/hierarchical-config/calc-max-profiles/?${searchParams.toString()}`;
      }
    },
    calcConstraintTemplateProfile: {
      all: '/api-v1/config/hierarchical-config/calc-constraint-template-profile-id/',
      update: `/api-v1/config/hierarchical-config/calc-constraint-template-profile-id/`,
      single: (id: number | bigint | undefined): string => `/api-v1/config/hierarchical-config/calc-constraint-template-profile-id/${id}/`,
      filter: (filters: {
        tenantId: number,
        channelDirection: string,
        cmtsId?: optionalNumber,
      }): string => {
        const {tenantId, channelDirection, cmtsId} = filters;
        const searchParams = new URLSearchParams();
        if (tenantId) {
          searchParams.append("tenant", Number(tenantId).toString());
        }
        if (channelDirection) {
          searchParams.append("channel_direction", channelDirection);
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        return `/api-v1/config/hierarchical-config/calc-constraint-template-profile-id/?${searchParams.toString()}`;
      }
    },
    templateProfile: {
      all: '/api-v1/config/template-profile/',
      import: "/api-v1/config/template-profile/import/",
      single: (id: number | bigint | undefined): string => `/api-v1/config/template-profile/${id}/`,
      filter: (filters: {
        tenant: number,
        channelDirection?: optionalString,
      }): string => {
        const {tenant, channelDirection} = filters;
        const searchParams = new URLSearchParams();
        if (tenant) {
          searchParams.append("tenant", Number(tenant).toString());
        }
        if (channelDirection) {
          searchParams.append("channel_direction", channelDirection);
        }
        return `/api-v1/config/template-profile/?${searchParams.toString()}`;
      }
    },
    automation: {
      // ChannelAutoProfileDeployConfig
      all: '/api-v1/config/automation/profile-deploy/channel/',
      single: (id: number | bigint | undefined): string => `/api-v1/config/automation/profile-deploy/channel/${id}/`,
      filter: (filters: {
        macDomainIfIndex?: optionalNumber,
        channelIfIndex?: optionalNumber,
        macDomainIfDescr?: optionalString,
        channelIfDescr?: optionalString,
        channelDirection?: optionalString,
        automatic?: optionalBoolean,
        cmts_id?: optionalNumber,
      }): string => {
        const {
          macDomainIfIndex,
          channelIfIndex,
          macDomainIfDescr,
          channelIfDescr,
          channelDirection,
          automatic,
          cmts_id} = filters;

        const searchParams = new URLSearchParams();

        if (macDomainIfIndex) {
          searchParams.append('mac_domain', Number(macDomainIfIndex).toString());
        }
        if (channelIfIndex) {
          searchParams.append('channel', Number(channelIfIndex).toString());
        }
        if (macDomainIfDescr) {
          searchParams.append('mac_domain_ifdescr', macDomainIfDescr);
        }
        if (channelIfDescr) {
          searchParams.append('channel_ifdescr', channelIfDescr);
        }
        if (channelDirection) {
          searchParams.append('channel_direction', channelDirection);
        }
        if (automatic) {
          searchParams.append('automatic', automatic.toString());
        }
        if (cmts_id) {
          searchParams.append('cmts_id', Number(cmts_id).toString());
        }

        return `/api-v1/config/automation/profile-deploy/channel/?${searchParams.toString()}`;
      }
    },
    cmts: {
      all: `/api-v1/config/cmts/`,
      single: (id: number | bigint | undefined): string => `/api-v1/config/cmts/${id}/`,
      filter: (filters : {regions?: optionalNumber[], limit?: optionalNumber, offset?: optionalNumber}): string => {
        const {regions, limit, offset} = filters;
        const searchParams = new URLSearchParams();
        if (regions) {
          searchParams.append("regions", regions.join());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        return `/api-v1/config/cmts/?${searchParams.toString()}`;
      }
    },
    tenant: {
      all: `/api-v1/config/tenant/`,
      single: (id: number | bigint | undefined): string => `/api-v1/config/tenant/${id}/`
    },
    cmtsTags: {
      all: `/api-v1/config/cmts-tags/`,
      single: (id: number | bigint | undefined): string => `/api-v1/config/cmts-tags/${id}/`,
      filter: (filters: { tenant?: number }): string => {
        const {tenant} = filters;
        const searchParams = new URLSearchParams();
        if (tenant) {
          searchParams.append("tenant_id", Number(tenant).toString());
        }
        return `/api-v1/config/cmts-tags/?${searchParams.toString()}`;
      }
    },
    schedule: {
      all: `/api-v1/config/schedule/`,
      subset: `/api-v1/config/schedule/batch/`,
      single: (id: number | bigint | undefined): string => `/api-v1/config/schedule/${id}/`,
      filter: (filters: { schedule_type?: string, tenant_id?: number }): string => {
        const {schedule_type, tenant_id} = filters;
        const searchParams = new URLSearchParams;
        if (schedule_type) {
          searchParams.append("schedule_type", schedule_type);
        }
        if (tenant_id) {
          searchParams.append("tenant_id", Number(tenant_id).toString());
        }
        return `/api-v1/config/schedule/?${searchParams.toString()}`;
      }
    }
  },
  action: {
    forceTopology: `/api-v1/pma/force-topology/`
  },
  pma: {
    search: `/api-v1/pma/search/`
  },
  topology: {
    topology_capture: {
      filter: (filters: { latest?: boolean, cmtsId?: optionalNumber }): string => {
        const {latest, cmtsId} = filters;
        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append("latest", "true");
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        return `/api-v1/topology/topology-capture/?${searchParams.toString()}`;
      },
      single: (id: number | bigint | undefined): string => `/api-v1/topology/topology-capture/${id}`
    },
    mac_domain: {
      filter: (filters: {
        latest?: boolean,
        cmtsId?: optionalNumber,
        macDomainIfIndex?: optionalNumber,
        regions?: optionalNumber[],
        limit?: optionalNumber,
        offset?: optionalNumber
      }): string => {
        const {latest, cmtsId, macDomainIfIndex, regions, limit, offset} = filters;
        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append("latest", "true");
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        if (macDomainIfIndex) {
          searchParams.append("ifIndex", Number(macDomainIfIndex).toString());
        }
        if (regions) {
          searchParams.append("regions", regions.join());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        return `/api-v1/topology/mac-domain/?${searchParams.toString()}`;
      },
      single: (id: number | bigint | undefined): string => `/api-v1/topology/mac-domain/${id}`
    },
    us_channel: {
      filter: (filters: {
        latest?: boolean,
        cmtsId?: optionalNumber,
        macDomainIfIndex?: optionalNumber,
        macDomain?: optionalNumber,
        channelIfIndex?: optionalNumber,
        limit?: optionalNumber,
        offset?: optionalNumber
      }): string => {
        const {
          latest,
          cmtsId,
          macDomainIfIndex,
          macDomain,
          channelIfIndex,
          limit,
          offset
        } = filters;
        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append("latest", "true");
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        if (macDomainIfIndex) {
          searchParams.append("mac_domain__ifIndex", Number(macDomainIfIndex).toString());
        }
        if (macDomain) {
          searchParams.append("mac_domain", Number(macDomain).toString());
        }
        if (channelIfIndex) {
          searchParams.append("ifIndex", Number(channelIfIndex).toString());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        return `/api-v1/topology/us-channel/?${searchParams.toString()}`;
      },
      single: (id: number | bigint | undefined): string => `/api-v1/topology/us-channel/${id}`
    },
    ds_channel: {
      filter: (filters: {
        latest?: boolean,
        cmtsId?: optionalNumber,
        macDomainIfIndex?: optionalNumber,
        macDomain?: optionalNumber,
        channelIfIndex?: optionalNumber,
        limit?: optionalNumber,
        offset?: optionalNumber
      }): string => {
        const {
          latest,
          cmtsId,
          macDomainIfIndex,
          macDomain,
          channelIfIndex,
          limit,
          offset
        } = filters;
        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append("latest", "true");
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        if (macDomainIfIndex) {
          searchParams.append("mac_domain__ifIndex", Number(macDomainIfIndex).toString());
        }
        if (macDomain) {
          searchParams.append("mac_domain", Number(macDomain).toString());
        }
        if (channelIfIndex) {
          searchParams.append("ifIndex", Number(channelIfIndex).toString());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        return `/api-v1/topology/ds-channel/?${searchParams.toString()}`;
      },
      single: (id: number | bigint | undefined): string => `/api-v1/topology/ds-channel/${id}`
    },
    cable_modem: {
      filter: (filters: {
        latest?: boolean,
        cmtsId?: optionalNumber,
        macDomainIfIndex?: optionalNumber,
        dsChannelIfIndex?: optionalNumber,
        usChannelIfIndex?: optionalNumber
      }): string => {
        const {
          latest,
          cmtsId,
          macDomainIfIndex,
          usChannelIfIndex,
          dsChannelIfIndex
        } = filters;
        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append("latest", "true");
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        if (macDomainIfIndex) {
          searchParams.append("mac_domain__ifIndex", Number(macDomainIfIndex).toString());
        }
        if (dsChannelIfIndex) {
          searchParams.append("ds_channel__ifIndex", Number(dsChannelIfIndex).toString());
        }
        if (usChannelIfIndex) {
          searchParams.append("us_channel__ifIndex", Number(usChannelIfIndex).toString());
        }
        return `/api-v1/topology/cable-modem/?${searchParams.toString()}`;
      },
      single: (id: number | bigint | undefined): string => `/api-v1/topology/cable-modem/${id}`
    },
    docsis3: {
      docsIfDownstreamChannel: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIfDownstreamChannel/?${searchParams.toString()}`;
        },
      },
      docsIfSignalQuality: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIfSignalQuality/?${searchParams.toString()}`;
        },
      },
      docsIf3MdChCfg: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIf3MdChCfg/?${searchParams.toString()}`;
        },
      },
      docsIf3SignalQualityExt: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIf3SignalQualityExt/?${searchParams.toString()}`;
        },
      },
      docsIf3CmtsCmRegStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIf3CmtsCmRegStatus/?${searchParams.toString()}`;
        },
      },
      docsIfUpstreamChannel: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIfUpstreamChannel/?${searchParams.toString()}`;
        },

      },
      docsIf3CmtsCmUsStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIf3CmtsCmUsStatus/?${searchParams.toString()}`;
        },

      },
      docsIf3DsChSet: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIf3DsChSet/?${searchParams.toString()}`;
        },
      },
      docsIf3UsChSet: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIf3UsChSet/?${searchParams.toString()}`;
        },
      },
      docsIf3MdNodeStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis3/docsIf3MdNodeStatus/?${searchParams.toString()}`;
        },
      },
    },
    docsis31: {
      docsIf31CmtsCmRegStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsCmRegStatus/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsDsOfdmChan: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber,
          ifIndex: optionalNumber
        }): string => {
          const {
            latest,
            cmtsId,
            captureId,
            ifIndex
          } = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          if (ifIndex) {
            searchParams.append("ifIndex", Number(ifIndex).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsDsOfdmChan/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsDsOfdmSubcarrierType: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsDsOfdmSubcarrierType/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsDsOfdmSubcarrierStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsDsOfdmSubcarrierStatus/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsDsOfdmProfileStats: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsDsOfdmProfileStats/?${searchParams.toString()}`;
        },
        single: (id: number | bigint | undefined): string => `/api-v1/topology/docsis31/docsIf31CmtsDsOfdmProfileStats/${id}`
      },
      docsIf31CmtsUsOfdmaChan: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber,
          ifIndex?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId, ifIndex} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          if (ifIndex) {
            searchParams.append("ifIndex", Number(ifIndex).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsUsOfdmaChan/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsUsOfdmaSubcarrierType: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsUsOfdmaSubcarrierType/?${searchParams.toString()}`;
        },

      },
      docsIf31CmtsUsOfdmaDataIucStats: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsUsOfdmaDataIucStats/?${searchParams.toString()}`;
        },

      },
      docsIf31CmtsUsOfdmaDataIucDetailStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsUsOfdmaDataIucDetailStatus/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsCmUsOfdmaChannelStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsCmUsOfdmaChannelStatus/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsCmUsOfdmaProfileStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsCmUsOfdmaProfileStatus/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsCmDsOfdmChannelStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsCmDsOfdmChannelStatus/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsCmDsOfdmProfileStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsCmDsOfdmProfileStatus/?${searchParams.toString()}`;
        },

      },
      docsIf31CmtsCmMdStats: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsCmMdStats/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsDsOfdmChanPower: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsDsOfdmChanPower/?${searchParams.toString()}`;
        },
      },
      docsIf31CmtsUsOfdmaRangingIucStatus: {
        filter: (filters: {
          latest?: boolean,
          cmtsId?: optionalNumber,
          captureId?: optionalNumber
        }): string => {
          const {latest, cmtsId, captureId} = filters;
          const searchParams = new URLSearchParams();
          if (latest) {
            searchParams.append("latest", "true");
          }
          if (cmtsId) {
            searchParams.append("cmts", Number(cmtsId).toString());
          }
          if (captureId) {
            searchParams.append("capture", Number(captureId).toString());
          }
          return `/api-v1/topology/docsis31/docsIf31CmtsUsOfdmaRangingIucStatus/?${searchParams.toString()}`;
        },
      },
    },
  },
  cb: {
    channelActivity: {
      all: (): string => `/api-v1/cb/channel-activity`,
      filter: (filters: {
        latest?: boolean,
        cmtsId?: optionalNumber,
        macDomainIfIndex?: optionalNumber,
        macDomainIfDescr?: optionalString,
        channelIfIndex?: optionalNumber,
        channelIfDescr?: optionalString,
        automatic?: boolean,
        offset?: optionalNumber,
        limit?: optionalNumber,
      }): string => {
        const {
          latest,
          cmtsId,
          macDomainIfIndex,
          macDomainIfDescr,
          channelIfIndex,
          channelIfDescr,
          automatic,
          offset,
          limit
        } = filters;
        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append('latest', 'true');
        }
        if (cmtsId) {
          searchParams.append('cmts', Number(cmtsId).toString());
        }
        if (macDomainIfIndex) {
          searchParams.append('mac_domain_ifindex', Number(macDomainIfIndex).toString());
        }
        if (macDomainIfDescr) {
          searchParams.append('mac_domain_ifdescr', Number(macDomainIfIndex).toString());
        }
        if (channelIfIndex) {
          searchParams.append('channel_ifindex', Number(channelIfIndex).toString());
        }
        if (channelIfDescr) {
          searchParams.append('channel_ifdescr', Number(channelIfIndex).toString());
        }
        if (automatic) {
          searchParams.append('automatic', automatic.toString());
        }
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        return `/api-v1/cb/channel-activity/?${searchParams.toString()}`;
      }
    },
    dsProfileSet: {
      all: (): string => `/api-v1/cb/ds-profile-set`,
      filter: (filters: {
        latest?: boolean,
        cmtsId?: optionalNumber,
        macDomainIfIndex?: optionalNumber,
        channelIfIndex?: optionalNumber
      }): string => {
        const {
          latest,
          cmtsId,
          macDomainIfIndex,
          channelIfIndex
        } = filters;

        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append("latest", "true");
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        if (macDomainIfIndex) {
          searchParams.append("mac_domain_ifindex", Number(macDomainIfIndex).toString());
        }
        if (channelIfIndex) {
          searchParams.append("ds_channel_ifindex", Number(channelIfIndex).toString());
        }
        return `/api-v1/cb/ds-profile-set/?${searchParams.toString()}`;
      }
    },
    usProfileSet: {
      all: (): string => `/api-v1/cb/us-profile-set`,
      filter: (filters: {
        latest?: boolean,
        cmtsId?: optionalNumber,
        macDomainIfIndex?: optionalNumber,
        channelIfIndex?: optionalNumber
      }): string => {
        const {
          latest,
          cmtsId,
          macDomainIfIndex,
          channelIfIndex} = filters;

        const searchParams = new URLSearchParams();
        if (latest) {
          searchParams.append("latest", "true");
        }
        if (cmtsId) {
          searchParams.append("cmts", Number(cmtsId).toString());
        }
        if (macDomainIfIndex) {
          searchParams.append("mac_domain_ifindex", Number(macDomainIfIndex).toString());
        }
        if (channelIfIndex) {
          searchParams.append("us_channel_ifindex", Number(channelIfIndex).toString());
        }
        return `/api-v1/cb/us-profile-set/?${searchParams.toString()}`;
      }
    },
    channelAutoDeploy: {
      all: (): string => `/api-v1/cb/channel-auto-deploy/`,
      single: (id: bigint): string => `/api-v1/cb/channel-auto-deploy/${id}`,
    },
    channelPlcRxmer: {
      all: (): string => `/api-v1/cb/channel-plc-rxmer/`,
      filter: (filters: {
        regions?: optionalNumber[],
        limit?: optionalNumber,
        offset?: optionalNumber
      }): string => {
        const {regions, offset, limit} = filters;
        const searchParams = new URLSearchParams();
        if (regions) {
          searchParams.append('regions', regions.join());
        }
        if (offset) {
          searchParams.append("offset", Number(offset).toString());
        }
        if (limit) {
          searchParams.append("limit", Number(limit).toString());
        }
        return `/api-v1/cb/channel-plc-rxmer/?${searchParams.toString()}`;
      }
    },

    // POST: { "ds_result_id": <ds_result_id> }
    deployDsCalculatedProfiles: `/api-v1/cb/deploy-ds-profiles/`,
    // POST: { "channel_id": <channel_id> }
    rollBackDsProfiles: `/api-v1/cb/roll-back-ds-profiles/`,

    // POST: { "ds_result_id": <us_result_id> }
    deployUsCalculatedProfiles: `/api-v1/cb/deploy-us-profiles/`,
    // POST: { "channel_id": <channel_id> }
    rollBackUsProfiles: `/api-v1/cb/roll-back-us-profiles/`,
  },
  referenceProfiles: {
    all: `/api-v1/reference-profiles/`,
  },
  trace: {
    all: (): string => "/api-v1/traces/traces/",
    filter: (filters: {
      latest?: optionalBoolean,
      cmts?: optionalNumber,
      mac_domain_ifindex?: optionalNumber,
      channel_ifindex?: optionalNumber,
      channel_direction?: optionalString,
      cm_reg_status_id?: optionalNumber,
      regions?: optionalNumber[],
      initiator?: optionalString,
      action_taken?: optionalString,
      timestamp_start__lt?: optionalNumber,
      timestamp_start__gt?: optionalNumber,
      timestamp_end__lt?: optionalNumber,
      timestamp_end__gt?: optionalNumber,
      offset?: optionalNumber,
      limit?: optionalNumber,
    }): string => {
      const {
        latest, cmts, mac_domain_ifindex,
        channel_ifindex, channel_direction, cm_reg_status_id,
        initiator, action_taken, regions,
        timestamp_start__lt, timestamp_start__gt,
        timestamp_end__lt, timestamp_end__gt,
        offset, limit
      } = filters;
      const searchParams = new URLSearchParams();
      if (latest) {
        searchParams.append("latest", "true");
      }
      if (cmts) {
        searchParams.append("cmts", Number(cmts).toString());
      }
      if (mac_domain_ifindex) {
        searchParams.append("mac_domain_ifindex", Number(mac_domain_ifindex).toString());
      }
      if (channel_ifindex) {
        searchParams.append("channel_ifindex", Number(channel_ifindex).toString());
      }
      if (channel_direction) {
        searchParams.append("channel_direction", channel_direction);
      }
      if (regions) {
        searchParams.append("regions", regions.join());
      }
      if (cm_reg_status_id) {
        searchParams.append("cm_reg_status_id", Number(cm_reg_status_id).toString());
      }
      if (initiator) {
        searchParams.append("initiator", initiator);
      }
      if (action_taken) {
        searchParams.append("action_taken", action_taken);
      }
      if (timestamp_start__lt) {
        searchParams.append("timestamp_start__lt", Number(timestamp_start__lt).toString());
      }
      if (timestamp_start__gt) {
        searchParams.append("timestamp_start__gt", Number(timestamp_start__gt).toString());
      }
      if (timestamp_end__lt) {
        searchParams.append("timestamp_end__lt", Number(timestamp_end__lt).toString());
      }
      if (timestamp_end__gt) {
        searchParams.append("timestamp_end__gt", Number(timestamp_end__gt).toString());
      }
      if (offset) {
        searchParams.append("offset", Number(offset).toString());
      }
      if (limit) {
        searchParams.append("limit", Number(limit).toString());
      }

      return `/api-v1/traces/traces/?${searchParams.toString()}`;
    },
  }
};
