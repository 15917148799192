import { NavRoutes } from './NavRoutes';
import React, { useMemo, useState, Dispatch, SetStateAction, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Collapse,
  Icon
} from '@mui/material';
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { MdCircle } from 'react-icons/md';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAuthContext } from '../Authentication/AuthContext';
import {
  DashNavIcon,
  ChannelViewNavIcon,
  RxmerViewNavIcon,
  ProfileManagementNavIcon,
  SystemConfigurationNavIcon,
  AdminNavIcon
} from '../Components/Icons';


export type NavMenuItemProps = {
  icon?: () => JSX.Element;
  expandedText: string;
  secondaryText?: string;
  smallText?: string;
  route: string;
  expanded: boolean;
  submenu?: boolean;
  onclick: () => void;
};

export type NavMenuCollapseProps = {
  icon: () => JSX.Element;
  expandedText: string;
  secondaryText?: string;
  smallText?: string;
  active: boolean;
  expanded: boolean;
  dropdownStatus: DropdownOpen;
  state: DropdownOpen;
  setState: Dispatch<SetStateAction<DropdownOpen>>;
  children: JSX.Element;
};

const ButtonLink = styled(Link)({
  textDecoration: 'none'
});

enum DropdownOpen {
  ADMIN = 'admin',
  NONE = 'none'
}

export function NavMenuItem(props: NavMenuItemProps): JSX.Element {
  const { icon, expandedText, smallText, secondaryText, route, expanded, submenu } = props;

  const usedSmallText = smallText ? smallText : expandedText;

  const resolvedPath = useResolvedPath(route);
  const active = (useMatch({ path: resolvedPath.pathname, end: true }) && true) || false;

  const theme = useTheme();
  const textColor = useMemo(() => {
    return active ? 'rgba(254, 254, 254, 1)' : 'rgba(254, 254, 254, 0.6)';
  }, [active, theme]);

  const iconStyle = useMemo(() => {
    return {
      px: '4px',
      py: '4px',
      mr: !expanded ? '16px' : '0',
      gap: '8px',
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
      borderTopRightRadius: expanded ? 0 : '6px',
      borderBottomRightRadius: expanded ? 0 : '6px',
      transition: theme.transitions.create(
        [
          'border-top-right-radius',
          'border-bottom-right-radius',
          'margin-right',
          'min-width',
          'height'
        ],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }
      ),
      width: '32px',
      minWidth: '56px',
      display: 'block',
      background:
        active && !submenu
          ? theme.palette.secondary.main
          : submenu && active
            ? theme.palette.primary.light
            : submenu && !active
              ? theme.palette.primary.dark
              : theme.palette.primary.main,
      color: textColor,
      overflow: 'hidden'
    };
  }, [active, theme, textColor, expanded]);

  const iconTextStyle = useMemo(() => {
    const expandedColor = active ? theme.palette.secondary.main : theme.palette.primary.main;
    return {
      color: expanded ? expandedColor : textColor,
      transition: theme.transitions.create(['color', 'height', 'display'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      height: expanded ? 0 : '21px',
      textAlign: 'center',
      fontFamily: 'Encode Sans Condensed',
      letterSpacing: '.02rem'
    };
  }, [active, theme, textColor, expanded]);

  const listItemStyle = useMemo(() => {
    return {
      px: '5px',
      pb: '8px',
      textAlign: expanded ? 'left' : 'center',
      background:
        active && !submenu
          ? theme.palette.secondary.main
          : submenu && active
            ? theme.palette.primary.light
            : submenu && !active
              ? theme.palette.primary.dark
              : theme.palette.primary.main,
      transition: theme.transitions.create(['height', 'padding-bottom'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    };
  }, [active, theme, textColor, expanded]);

  const textStyle = useMemo(() => {
    return {
      px: '4px',
      py: '8px',
      my: 0,
      gap: '8px',
      // letterSpacing: ".02rem",
      borderTopLeftRadius: expanded ? 0 : '6px',
      borderBottomLeftRadius: expanded ? 0 : '6px',
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
      transition: theme.transitions.create(
        ['border-top-left-radius', 'border-bottom-left-radius', 'height'],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }
      ),
      width: '100%',
      height: '40px',
      background:
        active && !submenu
          ? theme.palette.secondary.main
          : submenu && active
            ? theme.palette.primary.light
            : submenu && !active
              ? theme.palette.primary.dark
              : theme.palette.primary.main,
      color: active && submenu ? '#FEFEFE' : textColor
    };
  }, [active, theme, textColor, expanded]);

  return (
    <ButtonLink to={route} onClick={props.onclick}>
      <ListItem sx={listItemStyle}>
        <ListItemIcon sx={iconStyle}>
          {icon ? (
            <Typography sx={{ color: textColor, ml: '8px', mr: '8px' }} height="32px">
              {icon()}
            </Typography>
          ) : (
            <Typography
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ color: textColor, ml: '8px', mr: '8px' }}
              height="32px"
            >
              <MdCircle size="10px" style={active ? { color: '#FEFEFE' } : { color: textColor }} />
            </Typography>
          )}
          {/*<Typography variant="subtitle1" sx={iconTextStyle}>*/}
          {/*  {usedSmallText}*/}
          {/*</Typography>*/}
        </ListItemIcon>
        <ListItemText
          primary={expandedText}
          secondary={secondaryText}
          sx={textStyle}
          primaryTypographyProps={{
            fontSize: '.85rem',
            fontFamily: 'Encode Sans',
            fontWeight: !submenu ? '600' : '',
            letterSpacing: '.02rem'
          }}
        />
      </ListItem>
    </ButtonLink>
  );
}

export function NavMenuCollapse(props: NavMenuCollapseProps): JSX.Element {
  const {
    icon,
    expandedText,
    smallText,
    secondaryText,
    expanded,
    dropdownStatus,
    state,
    setState,
    children,
    active
  } = props;
  const usedSmallText = smallText ? smallText : expandedText;

  const theme = useTheme();
  const textColor = useMemo(() => {
    return active ? 'rgba(254, 254, 254, 1)' : 'rgba(254, 254, 254, 0.6)';
  }, [active, theme]);

  const iconStyle = useMemo(() => {
    return {
      px: '4px',
      py: '4px',
      mr: !expanded ? '16px' : 0,
      gap: '8px',
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
      borderTopRightRadius: expanded ? 0 : '6px',
      borderBottomRightRadius: expanded ? 0 : '6px',
      transition: theme.transitions.create(
        [
          'border-top-right-radius',
          'border-bottom-right-radius',
          'margin-right',
          'min-width',
          'height'
        ],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }
      ),
      width: '32px',
      minWidth: !expanded ? '56px' : '56px',
      display: 'block',
      background: active ? theme.palette.secondary.main : theme.palette.primary.main,
      color: textColor,
      overflow: 'hidden'
    };
  }, [active, theme, textColor, expanded]);

  const iconTextStyle = useMemo(() => {
    const expandedColor = active ? theme.palette.secondary.main : theme.palette.primary.main;
    return {
      color: expanded ? expandedColor : textColor,
      transition: theme.transitions.create(['color', 'height', 'display'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      height: expanded ? 0 : '21px',
      textAlign: 'center',
      fontFamily: 'Encode Sans Condensed'
      // letterSpacing: ".02rem"
    };
  }, [active, theme, textColor, expanded]);

  const listItemStyle = useMemo(() => {
    return {
      px: '5px',
      pb: expanded ? '20px' : '8px',
      cursor: 'pointer',
      textAlign: expanded ? 'left' : 'center',
      background: active ? theme.palette.secondary.main : theme.palette.primary.main,
      transition: theme.transitions.create(['height', 'padding-bottom'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    };
  }, [active, theme, textColor, expanded]);

  const textStyle = useMemo(() => {
    return {
      px: '4px',
      py: '8px',
      my: 0,
      gap: '8px',
      borderTopLeftRadius: expanded ? 0 : '6px',
      borderBottomLeftRadius: expanded ? 0 : '6px',
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
      transition: theme.transitions.create(
        ['border-top-left-radius', 'border-bottom-left-radius', 'height'],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }
      ),
      width: '100%',
      height: '40px',
      background: active ? theme.palette.secondary.main : theme.palette.primary.main,
      color: textColor
    };
  }, [active, theme, textColor, expanded]);

  const handleClick = () => {
    if (state === dropdownStatus) {
      setState(DropdownOpen.NONE);
    } else {
      setState(dropdownStatus);
    }
  };

  return (
    <>
      <ListItem sx={listItemStyle} onClick={handleClick}>
        <ListItemIcon sx={iconStyle}>
          <Typography sx={{ color: textColor, ml: '8px', mr: '8px' }} height="32px">
            {icon()}
          </Typography>
          {/*<Typography variant="subtitle1" sx={iconTextStyle}>*/}
          {/*  {usedSmallText}*/}
          {/*</Typography>*/}
        </ListItemIcon>
        <ListItemText
          primary={expandedText}
          secondary={secondaryText}
          sx={textStyle}
          primaryTypographyProps={{
            fontSize: '.85rem',
            fontFamily: 'Encode Sans',
            fontWeight: '600',
            letterSpacing: '.02rem'
          }}
        />
        {state === dropdownStatus ? (
          <ExpandLess sx={{ color: textColor }} onClick={handleClick} />
        ) : (
          <ExpandMore sx={{ color: textColor }} onClick={handleClick} />
        )}
      </ListItem>
      <Collapse
        in={state === dropdownStatus}
        timeout="auto"
        unmountOnExit
        sx={{ backgroundColor: theme.palette.primary.light }}
      >
        {expanded && children}
      </Collapse>
    </>
  );
}

export function MainMenu({ expanded }: { expanded: boolean }): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { userInfo } = useAuthContext();

  const [dropdown, setDropdown] = useState<DropdownOpen>(DropdownOpen.NONE);
  const [dropdownActive, setDropdownActive] = useState<DropdownOpen>(DropdownOpen.NONE);

  const resolvedManageUsers = useResolvedPath(NavRoutes.manage_users).pathname;

  //For when the page is refreshed...
  useEffect(() => {
    if (location.pathname.includes(resolvedManageUsers)) {
      handleAdminClick();
    } else {
      handleNormalClick();
    }
  }, []);

  const handleAdminClick = () => {
    setDropdownActive(DropdownOpen.ADMIN);
  };

  const handleNormalClick = () => {
    setDropdownActive(DropdownOpen.NONE);
  };

  return (
    <List component="nav">
      <IconContext.Provider value={{ size: '32px' }}>
        <NavMenuItem
          icon={DashNavIcon}
          expandedText={t('nav.overview')}
          route={NavRoutes.home}
          expanded={expanded}
          onclick={handleNormalClick}
        />
        <NavMenuItem
          icon={ChannelViewNavIcon}
          expandedText={t('nav.channel_view')}
          smallText={t('nav.channel')}
          route={NavRoutes.channel_view}
          expanded={expanded}
          onclick={handleNormalClick}
        />
        <NavMenuItem
          icon={RxmerViewNavIcon}
          expandedText={t('nav.rxmer_view')}
          smallText={t('nav.rxmer')}
          route={NavRoutes.rxmer_view}
          expanded={expanded}
          onclick={handleNormalClick}
        />
        {userInfo.role === 'Admin' &&
            <NavMenuItem
                icon={ProfileManagementNavIcon}
                expandedText={t('nav.profile_management')}
                smallText={t('nav.prof_mgmt')}
                route={NavRoutes.deployment.home}
                expanded={expanded}
                onclick={handleNormalClick}
            />
        }
        <NavMenuItem
          icon={SystemConfigurationNavIcon}
          expandedText={t('nav.system_configuration')}
          smallText={t('nav.config')}
          route={NavRoutes.config}
          expanded={expanded}
          onclick={handleNormalClick}
        />
        {/* ADMIN */}
        {userInfo.role === 'Admin' && (
          <NavMenuCollapse
            icon={AdminNavIcon}
            expandedText={t('nav.admin')}
            expanded={expanded}
            dropdownStatus={DropdownOpen.ADMIN}
            state={dropdown}
            setState={setDropdown}
            active={dropdownActive === DropdownOpen.ADMIN}
          >
            <>
              <NavMenuItem
                submenu={true}
                expandedText={t('nav.manage_users')}
                route={NavRoutes.manage_users}
                expanded={expanded}
                onclick={handleAdminClick}
              />
            </>
          </NavMenuCollapse>
        )}
      </IconContext.Provider>
    </List>
  );
}
