import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { UsChannel } from '../../Api/Types/Topology';
import { Typography } from '@mui/material';
import { ListApiResponse } from '../../Api/Types/General';
import { ApiRoutes } from '../../Api/ApiRoutes';
import Grid from '@mui/material/Unstable_Grid2';
import { FeedbackContextProvider } from '../../Feedback/FeedbackContext';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import { ApiGet } from '../../Api/Util';
import { useTranslation } from 'react-i18next';

type IdMap<T> = { [key: string]: T };

export type UsChannelSelectorComponentProp = {
  width?: number;
  direction?: string;
  macDomain: string | undefined;
  currentUsChannel: string;
  setCurrentUsChannel: Dispatch<SetStateAction<string>>;
  usChannelIdToUsChannel: IdMap<UsChannel>;
  setUsChannelIdToUsChannel: Dispatch<SetStateAction<IdMap<UsChannel>>>;
};

export function UsChannelSelectorComponentContent({
  width,
  direction,
  macDomain,
  currentUsChannel,
  setCurrentUsChannel,
  usChannelIdToUsChannel,
  setUsChannelIdToUsChannel
}: UsChannelSelectorComponentProp): JSX.Element {
  const { feedbackError } = useFeedbackContext();
  const { t } = useTranslation();

  const [selectedUsChannel, setSelectedUsChannel] = useState<string>('');

  const handleChannelChange = (event: SelectChangeEvent): void => {
    const id = event.target.value;
    setCurrentUsChannel(id);
  };

  useEffect(() => {
    ApiGet<ListApiResponse<UsChannel>>(
      ApiRoutes.topology.us_channel.filter({
        macDomain: Number(macDomain),
        latest: true,
        limit: 1000
      })
    )
      .then((response: ListApiResponse<UsChannel>) => {
        const usChannelIdToIfDescr: { [key: string]: UsChannel } = {};

        response.results.map(result => {
          if (result.channel_id === undefined) {
            return;
          }
          usChannelIdToIfDescr[Number(result.channel_id)] = result;
        });

        setUsChannelIdToUsChannel(usChannelIdToIfDescr);
      })
      .catch(error => {
        feedbackError(t('selectors.us_channel_error'));
      });
  }, [macDomain]);

  const usChannels = Object.values(usChannelIdToUsChannel).sort(function (a, b) {
    return a.ifDescr.localeCompare(b.ifDescr, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });

  const defaultKey = usChannels.length ? BigInt(usChannels[0].channel_id).toString() : 'none';

  useEffect(() => {
    if (usChannels.length) {
      if (currentUsChannel === '' || !usChannelIdToUsChannel[currentUsChannel]?.channel_id) {
        setCurrentUsChannel(Number(usChannels[0].channel_id).toString());
        setSelectedUsChannel(Number(usChannels[0].channel_id).toString());
      } else {
        setSelectedUsChannel(currentUsChannel);
      }
    } else {
      setSelectedUsChannel('none');
    }
  }, [usChannels, currentUsChannel]);

  return (
    <Grid lg={width ? width : 3} sx={{ padding: 0 }}>
      <FormControl
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Typography
          sx={{
            fontFamily: 'Encode Sans',
            alignSelf: 'start',
            padding: '7px',
            borderRadius: '6px 0px 0px 6px',
            color: '#fff',
            fontWeight: '600',
            whiteSpace: "nowrap",
            backgroundColor:
              direction === 'upstream'
                ? theme => theme.colors.direction.upstream
                : direction === 'downstream'
                  ? theme => theme.colors.direction.downstream
                  : theme => theme.palette.primary.main
          }}
        >
          {t('selectors.us_channel')}
        </Typography>
        <Select
          value={selectedUsChannel}
          onChange={handleChannelChange}
          displayEmpty
          label="Channel Selector"
          defaultValue={defaultKey}
          inputProps={{
            'aria-label': t('selectors.channel_aria'),
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#FEFEFE'
                }
              }
            }
          }}
          sx={{
            borderRadius: '0px 6px 6px 0',
            width: '100%',
            flex: 1,
            '& .MuiSvgIcon-root': {
              color: theme => theme.palette.secondary.main
            },
            '& .MuiOutlinedInput-notchedOutline span': {
              display: 'none'
            },
            '& .MuiSelect-select': {
              backgroundColor: '#FEFEFE'
            }
          }}
        >
          {usChannels.length ? (
            usChannels.map(channel => {
              return (
                <MenuItem key={Number(channel.channel_id)} value={Number(channel.channel_id)}>
                  {channel.ifDescr}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="none">
              <em>{t('selectors.none')}</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
  );
}

export function UsChannelSelectorComponent({
  width,
  direction,
  macDomain,
  currentUsChannel,
  setCurrentUsChannel,
  usChannelIdToUsChannel,
  setUsChannelIdToUsChannel
}: UsChannelSelectorComponentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <UsChannelSelectorComponentContent
        width={width}
        direction={direction}
        macDomain={macDomain}
        currentUsChannel={currentUsChannel}
        setCurrentUsChannel={setCurrentUsChannel}
        usChannelIdToUsChannel={usChannelIdToUsChannel}
        setUsChannelIdToUsChannel={setUsChannelIdToUsChannel}
      />
    </FeedbackContextProvider>
  );
}
