import {PmaDsProfile, PmaDsResult} from "../../../../Api/Types/CapacityBooster";
import {Cmts} from "../../../../Api/Types/Config";
import {DsChannel, MacDomain} from "../../../../Api/Types/Topology";
import {
  AddDefaultProfileIfMissing,
  ALLOWED_PROFILE_IDS,
  BITLOADING_TO_C100G_MODULATION,
  C100gModulation,
  DEFAULT_PROFILE_TYPE,
  FrequencyHz,
  INDENT
} from "./Common";
import {BigIntReplacer} from "../../../../Utils/Converters";
import {SimpleDeepCopy} from "../../../../Utils/SimpleDeepCopy";

export function ConstructC100gDsInstructions(
  t_no_escape: (key: string, options?: any) => string,
  cmts: Cmts | null,
  macDomain: MacDomain | null,
  dsChannel: DsChannel | null,
  pmaDsResult: PmaDsResult | null,
  firstNewOfdmProfId: string,
  includeSafetyChecks: boolean,
  includePmaDsResult: boolean
): string {
  const C100G_DS_CHAN_DESCR_RE
    = /OFDM Downstream (?<interfaceQamId>\d+\/\d+)\/(?<ofdmChannelId>\d+)/;

  type C100gOfdmProfileId = string;
  type C100gSubcarrierGroupId = number;

  // Exception zone, aka subcarrier group
  class C100gOfdmProfileExceptionZone {
    subcarrierGroupId: C100gSubcarrierGroupId | null = null;
    startFreqHz: FrequencyHz | null = null;
    endFreqHz: FrequencyHz | null = null;
    modulation: C100gModulation | null = null;
  }

  class C100gOfdmProfile {
    ofdmProfileId: C100gOfdmProfileId | null = null;
    channelProfileId: number | null = null;
    defaultModulation: C100gModulation | null = null;
    exceptionZones: C100gOfdmProfileExceptionZone[] = [];
    // logging only
    isDefaultProfile: boolean | null = null;
    meanBitLoading: number | null = null;

    getLines(): string[] {
      const lines: string[] = [];
      lines.push("");
      lines.push(`! ${t_no_escape("deploy.commands.c100g.ofdm_profile_info", {
        channel_profile_id: this.channelProfileId,
        bit_loading: this.meanBitLoading,
        profile_type: this.isDefaultProfile
          ? t_no_escape("deploy.commands.c100g.configure_profile_is_default")
          : t_no_escape("deploy.commands.c100g.configure_profile_is_calculated")
      })}`);
      lines.push(`ofdm profile ${this.ofdmProfileId}`);
      lines.push(`${INDENT}profile-modulation ${this.defaultModulation}`);
      this.exceptionZones.forEach(exceptionZone => {
        lines.push(`${INDENT}subcarrier-group ${exceptionZone.subcarrierGroupId}`
          + ` ${exceptionZone.startFreqHz} ${exceptionZone.endFreqHz}`
          + ` modulation ${exceptionZone.modulation}`);
      });
      lines.push(`exit`);
      return lines;
    }
  }

  const nextOfdmProfileId = function (): () => C100gOfdmProfileId {
    let counter: number = +firstNewOfdmProfId;
    if (isNaN(counter)) {
      counter = 0;
      return function (): C100gOfdmProfileId {
        const s: C100gOfdmProfileId = firstNewOfdmProfId + (counter == 0 ? "" : `+${counter}`);
        counter++;
        return s;
      };
    }
    return function (): C100gOfdmProfileId {
      const s: C100gOfdmProfileId = `${counter}`;
      counter++;
      return s;
    };
  }();

  // ====

  const mutablePmaDsResult = SimpleDeepCopy(pmaDsResult);
  const firstChannelProfileId = 1;
  const maxProfiles = 3;

  const resultsLines: string[] = [];
  resultsLines.push(`! ${t_no_escape("deploy.commands.instructions_title")}`);
  if (mutablePmaDsResult == null || !mutablePmaDsResult.calculated_profiles) {
    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.no_profile_data")}`);
    return resultsLines.join("\n");
  }

  resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.for_cmts_mac_domain_ds_channel", {
    cmts: mutablePmaDsResult.cmts_name,
    mac_domain: mutablePmaDsResult.md_ifdescr,
    ds_channel: mutablePmaDsResult.ds_ifdescr
  })}`);
  resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.for_pma_task_id_ds_result_id", {
    pma_task_id: mutablePmaDsResult.task,
    pma_ds_result_id: mutablePmaDsResult.id
  })}`);

  let timestamp = mutablePmaDsResult.result_completed_timestamp;
  timestamp = timestamp.replace("T", " ").replace("Z", " UTC");
  resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.profiles_calculated_at_timestamp", {
    timestamp: timestamp
  })}`);

  // update profile numbering
  mutablePmaDsResult.calculated_profiles.forEach((pmaDsProfile, index) => {
    pmaDsProfile.profile_id = index + firstChannelProfileId;
  });

  // limit the number of profiles
  if (mutablePmaDsResult.calculated_profiles.length > maxProfiles) {
    resultsLines.push(``);
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.too_many_ds_profiles")}`);
    mutablePmaDsResult.calculated_profiles.splice(maxProfiles);  // delete excess from end
  }

  // build ofdmProfiles
  const ofdmProfiles: C100gOfdmProfile[] = [];
  mutablePmaDsResult.calculated_profiles.forEach(pmaDsProfile => {
    const subcarrier_statuses = pmaDsProfile.ds_subcarrier_statuses;

    const ofdmProfile = new C100gOfdmProfile();
    ofdmProfile.ofdmProfileId = nextOfdmProfileId();
    ofdmProfile.channelProfileId = pmaDsProfile.profile_id
    ofdmProfile.isDefaultProfile = pmaDsProfile.profile_type == DEFAULT_PROFILE_TYPE;
    ofdmProfile.meanBitLoading = pmaDsProfile.mean_subcarrier_bit_loading;
    ofdmProfile.defaultModulation = BITLOADING_TO_C100G_MODULATION[pmaDsProfile.default_bit_loading];

    subcarrier_statuses.forEach(subcarrier_status => {
      if (subcarrier_status.main_bit_loading == pmaDsProfile.default_bit_loading) {
        return;
      }
      const exceptionZone = new C100gOfdmProfileExceptionZone();
      exceptionZone.modulation = BITLOADING_TO_C100G_MODULATION[subcarrier_status.main_bit_loading];
      exceptionZone.startFreqHz = subcarrier_status.start_frequency_hz;
      exceptionZone.endFreqHz = subcarrier_status.end_frequency_hz;
      ofdmProfile.exceptionZones.push(exceptionZone);
      exceptionZone.subcarrierGroupId = ofdmProfile.exceptionZones.length;
    });

    ofdmProfiles.push(ofdmProfile);
  });

  // get cmts-specific ids
  let ds_chan_info = mutablePmaDsResult.ds_ifdescr.match(C100G_DS_CHAN_DESCR_RE)?.groups;
  if (!ds_chan_info) {
    ds_chan_info = {
      interfaceQamId: t_no_escape("deploy.commands.c100g.unknown_interface_qam_id"),
      ofdmChannelId: t_no_escape("deploy.commands.c100g.unknown_ofdm_channel_id"),
    };
  }
  const interfaceQamId = ds_chan_info.interfaceQamId;
  const ofdmChannelId = ds_chan_info.ofdmChannelId;

  const profileIdToPmaDsProfile: { [p: number]: PmaDsProfile | null } = {};
  ALLOWED_PROFILE_IDS.forEach(profileId => {
    profileIdToPmaDsProfile[profileId] = null;
  });
  mutablePmaDsResult.calculated_profiles.forEach(pmaDsProfile => {
    profileIdToPmaDsProfile[pmaDsProfile.profile_id] = pmaDsProfile;
  });


  if (includeSafetyChecks) {
    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.safety_check_downstream_channel_intro", {
      interface_qam_id: interfaceQamId,
      ofdm_channel_id: ofdmChannelId,
    })}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_downstream_channel_do_command")}`);
    resultsLines.push(`show interface qam ${interfaceQamId}`
      + ` | include "ofdm-channel ${ofdmChannelId} shutdown"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_downstream_channel_check", {
      interface_qam_id: interfaceQamId,
      ofdm_channel_id: ofdmChannelId,
    })}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_downstream_channel_check_2", {
      expected_line: `no ofdm-channel ${ofdmChannelId} shutdown`,
    })}`);

    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.safety_check_create_ofdm_profile_intro")}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_create_ofdm_profile_do_command")}`);
    resultsLines.push(`show ofdm profile | include "ofdm profile"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.safety_check_create_ofdm_profile_check")}`);

    // check: channel frequency range matches new profiles?
    // check: subcarrier-spacing?
  }

  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.begin_configuration_heading")}`);
  resultsLines.push(`config`);

  // output ofdmProfiles
  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.create_ofdm_profiles_heading")}`);
  ofdmProfiles.forEach(ofdmModulationProfile => {
    ofdmModulationProfile.getLines().forEach(line => resultsLines.push(line));
  });

  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.set_channel_profiles_heading")}`);
  resultsLines.push(`interface qam ${interfaceQamId}`);
  ALLOWED_PROFILE_IDS.forEach(profileId => {
    if (profileId < firstChannelProfileId || profileId > maxProfiles) {
      return;
    }
    const ofdmProfile = ofdmProfiles[profileId - firstChannelProfileId]
    const pmaDsProfile = profileIdToPmaDsProfile[profileId];
    if (pmaDsProfile) {
      resultsLines.push(`! ${INDENT}${t_no_escape(
        "deploy.commands.c100g.configure_profile_id_default_bit_loading_mean_bit_loading",
        {
          profile_id: profileId,
          default_bit_loading: pmaDsProfile.default_bit_loading,
          mean_bit_loading: pmaDsProfile.mean_subcarrier_bit_loading,
          profile_type: pmaDsProfile.profile_type == DEFAULT_PROFILE_TYPE 
            ? t_no_escape("deploy.commands.c100g.configure_profile_is_default")
            : t_no_escape("deploy.commands.c100g.configure_profile_is_calculated")
        })}`);
      resultsLines.push(`${INDENT}ofdm-channel ${ofdmChannelId} profile ${profileId} ${ofdmProfile.ofdmProfileId}`);
    } else {
      resultsLines.push(`! ${INDENT}${t_no_escape(
        "deploy.commands.c100g.configure_profile_id_unused",
        {profile_id: profileId})}`);
      resultsLines.push(`${INDENT}no ofdm-channel ${ofdmChannelId} profile ${profileId}`);
    }
  });
  resultsLines.push(`exit`);

  if (includeSafetyChecks) {
    resultsLines.push("");
    resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.clean_up_ofdm_profile_intro")}`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.clean_up_ofdm_profile_do_command")}`);
    resultsLines.push(`show ofdm profile | include "ofdm profile"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.clean_up_ofdm_profile_check")}`);
    resultsLines.push(`show running-config | include "ofdm-channel . profile . <profile_id>"`);
    resultsLines.push(`! ${INDENT}${t_no_escape("deploy.commands.c100g.clean_up_ofdm_profile_check_2")}`);
    resultsLines.push(`no ofdm profile <profile_id>`);
  }

  resultsLines.push("");
  resultsLines.push(`! ${t_no_escape("deploy.commands.c100g.configuration_complete_heading")}`);
  resultsLines.push(`exit`);

  // output debug info if requested
  if (includePmaDsResult) {
    resultsLines.push("");
    resultsLines.push(`! pmaDsResult: ${JSON.stringify(mutablePmaDsResult, BigIntReplacer)}`);
  }

  return resultsLines.join("\n");
}
