import { Button, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useFeedbackContext } from '../Feedback/FeedbackContext';
import { ApiRoutes } from '../Api/ApiRoutes';
import { ApiGet, ApiPost, ApiPut } from '../Api/Util';
import { ListApiResponse } from '../Api/Types/General';
import { ChannelActivityType, PmaDsResult, PmaUsResult } from '../Api/Types/CapacityBooster';
import { ConfirmationDialog, ConfirmationDialogColors } from './Dialogs/ConfirmationDialog';
import { ChannelAutoProfileDeployConfig } from '../Api/Types/Config';

export type DeployProfilesResponse = {
  status: string;
  trace_id: bigint;
};

export type ChannelActivityButtonBarProps = {
  selectedChannels: ChannelActivityType[];
  disabled?: boolean;
};

export function ChannelActivityDeployCalculatedProfiles({
  selectedChannels,
  disabled
}: ChannelActivityButtonBarProps): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleClick = useCallback(() => {
    selectedChannels.forEach(channel => {
      getProfile(channel);
      getConfigSettings(channel);
    });
  }, [selectedChannels]);

  const getProfile = (channel: ChannelActivityType) => {
    const url = channel.channel_direction === 'downstream' ? ApiRoutes.cb.dsProfileSet : ApiRoutes.cb.usProfileSet;
    ApiGet<ListApiResponse<PmaDsResult | PmaUsResult>>(
      url.filter({
        latest: true,
        cmtsId: Number(channel.cmts),
        macDomainIfIndex: Number(channel.mac_domain_ifindex),
        channelIfIndex: Number(channel.channel_ifindex)
      })
    ).then(async (response: ListApiResponse<PmaDsResult | PmaUsResult>) => {
      const url =
        channel.channel_direction == 'downstream'
          ? ApiRoutes.cb.deployDsCalculatedProfiles
          : ApiRoutes.cb.deployUsCalculatedProfiles;
      const data =
        channel.channel_direction == 'downstream'
          ? { ds_result_id: Number(response?.results[0]?.id) }
          : { us_result_id: Number(response?.results[0]?.id) };
      deployProfile(url, data);
    }).catch(error => {
      setFeedbackState({
        open: true,
        severity: 'error',
        message: `${t('channel_activity.fail_get_profile')}`
      });
    });
  };

  const getConfigSettings = (channel: ChannelActivityType) => {
    ApiGet<ListApiResponse<ChannelAutoProfileDeployConfig>>(
      ApiRoutes.config.automation.filter({
        cmts_id: Number(channel.cmts),
        macDomainIfIndex: Number(channel.mac_domain_ifindex),
        channelIfIndex: Number(channel.channel_ifindex),
      })
    ).then(async (response: ListApiResponse<ChannelAutoProfileDeployConfig>) => {
      if (response?.count !== 0) {
        disableAutoDeployment(Number(response?.results[0].id));
      }
    }).catch(error => {
      setFeedbackState({
        open: true,
        severity: 'error',
        message: `${t('channel_activity.fail_get_config_settings')}`
      });
    });
  };

  const disableAutoDeployment = (channel: number) => {
    const data = { automatic: false }; // ChannelAutoProfileDeployConfig
    ApiPut(ApiRoutes.config.automation.single(channel), data)
      .then(async () => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.success_stop_auto_deploy')}`,
          severity: 'success'
        });
      })
      .catch(reason => {
        setFeedbackState({
          open: true,
          severity: 'error',
          message: reason.message
        });
      });
  };

  const deployProfile = (url: string, data: any) => {
    ApiPost<DeployProfilesResponse>(url, data)
      .then((value: DeployProfilesResponse) => {
        setFeedbackState({
          open: true,
          severity: 'success',
          message: t('deploy.deploy_calc_to_channel_success')
        });
      })
      .catch(reason => {
        setFeedbackState({
          open: true,
          severity: 'error',
          message: reason.message
        });
      });
  };

  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid xs={12} lg={3}>
      <ConfirmationDialog
        title={t('channel_activity.deploy_channel')}
        open={openConfirmationDialog}
        message={t('channel_activity.deploy_channel_message', { count: selectedChannels.length })}
        subMessage={t('channel_activity.deploy_channel_sub_message')}
        confirmText={t('channel_activity.deploy_channel_confirm_text')}
        confirmButton={t('channel_activity.deploy_channel_confirm_button')}
        channels={selectedChannels}
        colorType={ConfirmationDialogColors.INFO}
        onClose={value => {
          setOpenConfirmationDialog(false);
          if (value) {
            handleClick();
          }
        }}
        testId={t('channel_activity.deploy_channel_test_id')}
      >
        <Button
          variant="contained"
          size="large"
          color="success"
          fullWidth
          onClick={() => setOpenConfirmationDialog(true)}
          sx={{
            borderRadius: '8px',
            border: '1px solid rgba(68, 85, 108, 1)',
            backgroundColor: 'rgba(89, 109, 136, 1)',
            color: 'rgba(250, 250, 250, 1)',
            padding: '16px',
            '&:hover': {
              backgroundColor: 'rgba(250, 250, 250, 1)',
              color: 'rgba(89, 109, 136, 1)'
            }
          }}
          disabled={disabled}
        >
          {t('channel_activity.deploy_channel')}
        </Button>
      </ConfirmationDialog>
    </Grid>
  );
}

export function ChannelActivityRollBackProfiles({
  selectedChannels,
  disabled
}: ChannelActivityButtonBarProps): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleClick = useCallback(() => {
    selectedChannels.forEach(channel => {
      const url =
        channel.channel_direction == 'downstream'
          ? ApiRoutes.cb.rollBackDsProfiles
          : ApiRoutes.cb.rollBackUsProfiles;
      const data = { channel_id: Number(channel?.channel_id) };
      rollBackProfiles(url, data);
      getConfigSettings(channel);
    });
  }, [selectedChannels]);

  const rollBackProfiles = (url: string, data: any) => {
    ApiPost<DeployProfilesResponse>(url, data)
      .then((value: DeployProfilesResponse) => {
        setFeedbackState({
          open: true,
          severity: 'success',
          message: t('deploy.roll_back_profiles_success')
        });
      })
      .catch(reason => {
        setFeedbackState({
          open: true,
          severity: 'error',
          message: reason.message
        });
      });
  };

  const getConfigSettings = (channel: ChannelActivityType) => {
    ApiGet<ListApiResponse<ChannelAutoProfileDeployConfig>>(
      ApiRoutes.config.automation.filter({
        cmts_id: Number(channel.cmts),
        macDomainIfIndex: Number(channel.mac_domain_ifindex),
        channelIfIndex: Number(channel.channel_ifindex),
      })
    )
      .then(async (response: ListApiResponse<ChannelAutoProfileDeployConfig>) => {
        if (response.count !== 0) {
          disableAutoDeployment(Number(response.results[0].id));
        }
      })
      .catch(reason => {
        setFeedbackState({
          open: true,
          severity: 'error',
          message: reason.message
        });
      });
  };

  const disableAutoDeployment = (channel: number) => {
    const data = { automatic: false }; // ChannelAutoProfileDeployConfig
    ApiPut(ApiRoutes.config.automation.single(channel), data)
      .then(async () => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.success_stop_auto_deploy')}`,
          severity: 'success'
        });
      })
      .catch(reason => {
        setFeedbackState({
          open: true,
          severity: 'error',
          message: reason.message
        });
      });
  };

  return (
    <Grid xs={12} lg={3}>
      <ConfirmationDialog
        title={t('channel_activity.roll_back_profiles')}
        open={openConfirmationDialog}
        message={t('channel_activity.roll_back_profiles_message', { count: selectedChannels.length })}
        subMessage={t('channel_activity.roll_back_profiles_sub_message')}
        confirmText={t('channel_activity.roll_back_profiles_confirm_text')}
        confirmButton={t('channel_activity.roll_back_profiles_confirm_button')}
        channels={selectedChannels}
        configText={true}
        colorType={ConfirmationDialogColors.WARNING}
        onClose={value => {
          setOpenConfirmationDialog(false);
          if (value) {
            handleClick();
          }
        }}
        testId={t('channel_activity.roll_back_profiles_test_id')}
      >
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={() => setOpenConfirmationDialog(true)}
          sx={{
            borderRadius: '8px',
            border: '1px solid #D27E20',
            backgroundColor: 'rgba(245, 245, 245, 1)',
            color: '#D27E20',
            padding: '16px',
            '&:hover': {
              backgroundColor: '#D27E20',
              color: 'rgba(245, 245, 245, 1)'
            }
          }}
          disabled={disabled}
        >
          {t('channel_activity.roll_back_profiles')}
        </Button>
      </ConfirmationDialog>
    </Grid>
  );
}

export function ChannelActivityScheduleAutomatedProfile({
  selectedChannels,
  disabled
}: ChannelActivityButtonBarProps): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleClick = useCallback(() => {
    selectedChannels.forEach(channel => {
      getConfigSettings(channel);
    });
  }, [selectedChannels]);

  const getConfigSettings = (channel: ChannelActivityType) => {
    ApiGet<ListApiResponse<ChannelAutoProfileDeployConfig>>(
      ApiRoutes.config.automation.filter({
        cmts_id: Number(channel.cmts),
        macDomainIfIndex: Number(channel.mac_domain_ifindex),
        channelIfIndex: Number(channel.channel_ifindex),
      })
    ).then(async (response: ListApiResponse<ChannelAutoProfileDeployConfig>) => {
      if (response.count === 0) {
        createConfigSettings(channel);
      } else {
        enableAutoDeployment(Number(response.results[0].id));
      }
    });
  };

  const createConfigSettings = (channel: ChannelActivityType) => {
    const data = { // ChannelAutoProfileDeployConfig
      cmts: Number(channel.cmts),
      mac_domain_ifindex: Number(channel.mac_domain_ifindex),
      mac_domain_ifdescr: channel.mac_domain_ifdescr,
      channel_ifindex: Number(channel.channel_ifindex),
      channel_ifdescr: channel.channel_ifdescr,
      channel_direction: channel.channel_direction === 'downstream' ? 'D' : 'U',
      tenant: 1,
      automatic: true
    };

    ApiPost(ApiRoutes.config.automation.all, data).then(
      async response => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.success_auto_deploy')}`,
          severity: 'success'
        });
      },
      error => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.failed_auto_deploy')}: ${error.message}`,
          severity: 'error'
        });
      }
    );
  };

  const enableAutoDeployment = (channel: number) => {
    const data = { automatic: true }; // ChannelAutoProfileDeployConfig
    ApiPut(ApiRoutes.config.automation.single(channel), data).then(
      async response => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.success_auto_deploy')}`,
          severity: 'success'
        });
      },
      error => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.failed_auto_deploy')}`,
          severity: 'error'
        });
      }
    );
  };

  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid xs={12} lg={3}>
      <ConfirmationDialog
        title={t('channel_activity.start_auto_deploy')}
        open={openConfirmationDialog}
        message={t('channel_activity.auto_deploy_message', { count: selectedChannels.length })}
        subMessage={t('channel_activity.auto_deploy_sub_message')}
        confirmText={t('channel_activity.auto_deploy_confirm_text')}
        confirmButton={t('channel_activity.auto_deploy_confirm_button')}
        channels={selectedChannels}
        colorType={ConfirmationDialogColors.INFO}
        onClose={value => {
          setOpenConfirmationDialog(false);
          if (value) {
            handleClick();
          }
        }}
        testId={t('channel_activity.auto_deploy_test_id')}
      >
        <Button
          variant="contained"
          size="large"
          color="success"
          fullWidth
          onClick={() => setOpenConfirmationDialog(true)}
          sx={{
            borderRadius: '8px',
            border: '1px solid rgba(68, 85, 108, 1)',
            backgroundColor: 'rgba(68, 85, 108, 1)',
            color: 'rgba(245, 245, 245, 1)',
            padding: '16px',
            '&:hover': {
              backgroundColor: 'rgba(245, 245, 245, 1)',
              color: 'rgba(68, 85, 108, 1)'
            }
          }}
          disabled={disabled}
        >
          {t('channel_activity.start_auto_deploy')}
        </Button>
      </ConfirmationDialog>
    </Grid>
  );
}

export function ChannelActivityDisableAutomatedProfile({
  selectedChannels,
  disabled
}: ChannelActivityButtonBarProps): JSX.Element {
  const { t } = useTranslation();
  const { setFeedbackState } = useFeedbackContext();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleClick = useCallback(() => {
    selectedChannels.forEach(channel => {
      getConfigSettings(channel);
    });
  }, [selectedChannels]);

  const getConfigSettings = (channel: ChannelActivityType) => {
    ApiGet<ListApiResponse<ChannelAutoProfileDeployConfig>>(
      ApiRoutes.config.automation.filter({
        cmts_id: Number(channel.cmts),
        macDomainIfIndex: Number(channel.mac_domain_ifindex),
        channelIfIndex: Number(channel.channel_ifindex),
      })
    ).then(async (response: ListApiResponse<ChannelAutoProfileDeployConfig>) => {
      if (response.count === 0) {
        createConfigSettings(channel);
      } else {
        disableAutoDeployment(Number(response.results[0].id));
      }
    });
  };

  const createConfigSettings = (channel: ChannelActivityType) => {
    const data = { // ChannelAutoProfileDeployConfig
      cmts: Number(channel.cmts),
      mac_domain_ifindex: Number(channel.mac_domain_ifindex),
      mac_domain_ifdescr: channel.mac_domain_ifdescr,
      channel_ifindex: Number(channel.channel_ifindex),
      channel_ifdescr: channel.channel_ifdescr,
      channel_direction: channel.channel_direction === 'downstream' ? 'D' : 'U',
      tenant: 1,
      automatic: false
    };

    ApiPost(ApiRoutes.config.automation.all, data).then(
      async response => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.success_stop_auto_deploy')}`,
          severity: 'success'
        });
      },
      error => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.failed_stop_auto_deploy')}: ${error.message}`,
          severity: 'error'
        });
      }
    );
  };

  const disableAutoDeployment = (channel: number) => {
    const data = { automatic: false }; // ChannelAutoProfileDeployConfig
    ApiPut(ApiRoutes.config.automation.single(channel), data).then(
      async response => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.success_stop_auto_deploy')}`,
          severity: 'success'
        });
      },
      error => {
        setFeedbackState({
          open: true,
          message: `${t('channel_activity.failed_stop_auto_deploy')}`,
          severity: 'error'
        });
      }
    );
  };

  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid xs={12} lg={3}>
      <ConfirmationDialog
        title={t('channel_activity.stop_auto_deploy')}
        open={openConfirmationDialog}
        message={t('channel_activity.stop_auto_deploy_message', { count: selectedChannels.length })}
        subMessage={t('channel_activity.stop_auto_deploy_sub_message')}
        confirmText={t('channel_activity.stop_auto_deploy_confirm_text')}
        confirmButton={t('channel_activity.stop_auto_deploy_confirm_button')}
        channels={selectedChannels}
        colorType={ConfirmationDialogColors.ERROR}
        configText={true}
        onClose={value => {
          setOpenConfirmationDialog(false);
          if (value) {
            handleClick();
          }
        }}
        testId={t('channel_activity.stop_auto_deploy_test_id')}
      >
        <Button
          variant="contained"
          size="large"
          color="success"
          fullWidth
          onClick={() => setOpenConfirmationDialog(true)}
          sx={{
            borderRadius: '8px',
            border: '1px solid rgba(68, 85, 108, 1)',
            backgroundColor: 'rgba(245, 245, 245, 1)',
            color: 'rgba(166, 24, 24, 1)',
            padding: '16px',
            '&:hover': {
              backgroundColor: 'rgba(166, 24, 24, 1)',
              color: 'rgba(245, 245, 245, 1)'
            }
          }}
          disabled={disabled}
        >
          {t('channel_activity.stop_auto_deploy')}
        </Button>
      </ConfirmationDialog>
    </Grid>
  );
}

type ActionButtonBarProps = {
  children: JSX.Element[] | JSX.Element | null;
};

export function ChannelActivityButtonBar({ children }: ActionButtonBarProps): JSX.Element {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid xs={12} display="flex" gap={1} sx={{ p: 0 }}>
      {children}
    </Grid>
  );
}
