import { useState, useEffect, Dispatch, SetStateAction } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {DsChannel, MacDomain, UsChannel} from "../../Api/Types/Topology";
import { Typography } from "@mui/material";
import { ListApiResponse } from "../../Api/Types/General";
import { ApiRoutes } from "../../Api/ApiRoutes";
import Grid from "@mui/material/Unstable_Grid2";
import { FeedbackContextProvider } from "../../Feedback/FeedbackContext";
import { useFeedbackContext } from "../../Feedback/FeedbackContext";
import { ApiGet } from "../../Api/Util";
import {useTranslation} from "react-i18next";

type IdMap<T> = { [key: string]: T };

interface IdDsChannel extends DsChannel {
  id: string;
}
interface IdUsChannel extends UsChannel {
  id: string;
}
type DsUsChannel = IdDsChannel | IdUsChannel;

export type ChannelSelectorComponentProp = {
  width?: number;
  direction?: string;
  cmtsId: string | undefined;
  macDomainIfIndex: string;
  currentChannel: string;
  setCurrentChannel: Dispatch<SetStateAction<string>>;
  channelIdToChannel: IdMap<DsChannel | UsChannel>;
  setChannelIdToChannel: Dispatch<SetStateAction<IdMap<DsChannel | UsChannel>>>;
  firstItem?: boolean;
};

export function ChannelSelectorComponentContent({
  width,
  direction,
  cmtsId,
  macDomainIfIndex,
  currentChannel,
  setCurrentChannel,
  channelIdToChannel,
  setChannelIdToChannel,
  firstItem
}: ChannelSelectorComponentProp): JSX.Element {
  const { feedbackError } = useFeedbackContext();
  const { t } = useTranslation();

  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [channelList, setChannelList] = useState<DsUsChannel[]>([]);

  const handleChannelChange = (event: SelectChangeEvent): void => {
    const id = event.target.value;
    setCurrentChannel(id);
  };

  const naturalSort = (array: DsChannel[] | UsChannel[]): UsChannel[] | DsChannel[] => {
    array = array.sort((a, b) => {
      return a.ifDescr.localeCompare(b.ifDescr, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
    return array;
  };

  const naturalSortDsUSChannels = (array: DsUsChannel[]): DsUsChannel[] => {
    array = array.sort((a, b) => {
      return a.ifDescr.localeCompare(b.ifDescr, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
    return array;
  }

  const naturalSortMacDomain = (array: MacDomain[]): MacDomain[] => {
    array = array.sort((a, b) => {
      return a.ifDescr.localeCompare(b.ifDescr, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
    return array;
  };

  useEffect(() => {
    const combinedChannels: DsUsChannel[] = [];
    ApiGet<ListApiResponse<MacDomain>>(
      ApiRoutes.topology.mac_domain.filter({
        cmtsId: Number(cmtsId),
        macDomainIfIndex: Number(macDomainIfIndex),
        latest: true,
        limit: 1000
      })
    )
      .then((response: ListApiResponse<MacDomain>) => {
        const combinedChannelsIdtoCombinedChannels: {
          [key: string]: DsChannel | UsChannel;
        } = {};

        if (response.results.length) {
          const sortedMacDomains = naturalSortMacDomain(response.results);
          sortedMacDomains.map(macdomain => {
            const dsChannels: DsChannel[] = naturalSort(macdomain?.ds_channels.length
              ? macdomain?.ds_channels
              : []);
            const idDsChannels = dsChannels.map(dsChannel => {
              const idDsChannel = dsChannel as IdDsChannel;
              idDsChannel.id = 'D' + idDsChannel.channel_id
              return idDsChannel;
            });
            combinedChannels.push(...idDsChannels);
            const usChannels: UsChannel[] = naturalSort(macdomain?.us_channels.length
              ? macdomain?.us_channels
              : []);
            const idUsChannels = usChannels.map(usChannel => {
              const idUsChannel = usChannel as IdUsChannel;
              idUsChannel.id = 'U' + idUsChannel.channel_id
              return idUsChannel;
            });
            combinedChannels.push(...idUsChannels);
          });

          const sortedCombinedChannels = naturalSortDsUSChannels(combinedChannels);

          setChannelList(sortedCombinedChannels);
          sortedCombinedChannels.map((channel) => {
            if (channel.channel_id === undefined) {
              return;
            }
            combinedChannelsIdtoCombinedChannels[channel.id] =
              channel;
          });

          setChannelIdToChannel(combinedChannelsIdtoCombinedChannels);

        }else{
          setChannelList([]);
        }



      })
      .catch((error) => {
        feedbackError(t("selectors.channel_error"));
      });
  }, [cmtsId, macDomainIfIndex]);


  const defaultKey = channelList.length
    ? channelList[0].id
    : "none";

  useEffect(() => {
    if (channelList.length) {
      if (
        currentChannel === "" ||
        !currentChannel ||
        !channelIdToChannel[currentChannel]?.channel_id
      ) {
        if(currentChannel === 'all'){
          setCurrentChannel("all");
          setSelectedChannel("all");
        }else if(firstItem){
          setCurrentChannel(channelList[0].id);
          setSelectedChannel(channelList[0].id);
        }else{
          setCurrentChannel("all");
          setSelectedChannel("all");
        }
      } else {
        setSelectedChannel(currentChannel);
      }
    } else {
      setSelectedChannel("none");
    }
  }, [channelList, currentChannel]);

  return (
    <Grid lg={width ? width : 3} sx={{ padding: 0 }}>
      <FormControl
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Typography
          sx={{
            fontFamily: 'Encode Sans',
            alignSelf: 'start',
            padding: "8px 7px",
            borderRadius: '6px 0px 0px 6px',
            color: '#fff',
            fontWeight: '600',
            backgroundColor:
              direction === 'upstream'
                ? theme => theme.colors.direction.upstream
                : direction === 'downstream'
                  ? theme => theme.colors.direction.downstream
                  : theme => theme.palette.primary.main
          }}
        >
          {t('selectors.channel')}
        </Typography>
        <Select
          value={selectedChannel}
          onChange={handleChannelChange}
          displayEmpty
          label={t('selectors.channel_aria')}
          defaultValue={defaultKey}
          inputProps={{
            'aria-label': t('selectors.channel_aria'),
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#FEFEFE'
                }
              }
            }
          }}
          sx={{
            borderRadius: '0px 6px 6px 0',
            width: '100%',
            flex: 1,
            '& .MuiSvgIcon-root': {
              color: theme => theme.palette.secondary.main
            },
            '& .MuiOutlinedInput-notchedOutline span': {
              display: 'none'
            },
            '& .MuiSelect-select': {
              backgroundColor: '#FEFEFE'
            }
          }}
        >
          <MenuItem value="all">{t('selectors.all')}</MenuItem>
          {channelList.length ? (
            channelList.map(channel => {
              return (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.ifDescr}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="none">
              <em>{t('selectors.none')}</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
  );
}

export function ChannelSelectorComponent({
  width,
  direction,
  cmtsId,
  macDomainIfIndex,
  currentChannel,
  setCurrentChannel,
  channelIdToChannel,
  setChannelIdToChannel,
  firstItem
}: ChannelSelectorComponentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <ChannelSelectorComponentContent
        width={width}
        direction={direction}
        cmtsId={cmtsId}
        macDomainIfIndex={macDomainIfIndex}
        currentChannel={currentChannel}
        setCurrentChannel={setCurrentChannel}
        channelIdToChannel={channelIdToChannel}
        setChannelIdToChannel={setChannelIdToChannel}
        firstItem={firstItem}
      />
    </FeedbackContextProvider>
  );
}
