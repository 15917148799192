import { FeedbackContextProvider, useFeedbackContext } from '../Feedback/FeedbackContext';
import React, {useEffect, useState} from "react";
import {PmaDsResult} from "../Api/Types/CapacityBooster";
import {ApiGet} from "../Api/Util";
import {ListApiResponse} from "../Api/Types/General";
import {ApiRoutes} from "../Api/ApiRoutes";
import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid-pro";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import DataTable from "../Components/DataTable";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import {Typography} from "@mui/material";
import {FakePmaDsResultArray} from "../Mocks/FakeData";

export type PLCTableProps = {
  cmtsId: number;
  macDomainIfIndex: number;
  channelIfIndex: number;
};

export function PLCTableContent({
  cmtsId,
  macDomainIfIndex,
  channelIfIndex
}: PLCTableProps): JSX.Element {

  const { t } = useTranslation();
  const { feedbackError } = useFeedbackContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PmaDsResult[]>([]);

  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    setLoading(true);
    ApiGet<ListApiResponse<PmaDsResult>>(
      ApiRoutes.cb.dsProfileSet.filter({
        latest: true,
        cmtsId: cmtsId,
        macDomainIfIndex: macDomainIfIndex,
        channelIfIndex: channelIfIndex,
      }),
    )
      .then(async (response) => {
        setData(response.results);
        // setData(FakePmaDsResultArray); //REMOVE THIS LINE
        setTotalRows((prevTotalRows) =>
          response?.count !== undefined
            ? response?.count
            : prevTotalRows,
        );
      })
      .catch((error) => {
        feedbackError(t("plc_table.error"));
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'plc_current_frequency_hz_range',
      headerName: t("plc_table.plc_current_frequency_hz_range"),
      flex: 1,
      renderCell: ({ value }) => {
        return value ? `${value[0]} Hz - ${value[1]} Hz` : t("plc_table.no_data");
      }
    },
    {
      field: 'plc_current_min_avg_rxmer_db',
      headerName: t("plc_table.plc_current_min_avg_rxmer_db"),
      flex: 1,
      valueGetter: (value: number) => {
        return value ? value : t("plc_table.no_data");
      }
    },
    {
      field: 'plc_recommended_frequency_hz_range',
      headerName: t("plc_table.plc_recommended_frequency_hz_range"),
      flex: 1,
      renderCell: ({ value }) => {
        return value ? `${value[0]} Hz - ${value[1]} Hz` : t("plc_table.no_data");
      }
    },
    {
      field: 'plc_recommended_min_avg_rxmer_db',
      headerName: t("plc_table.plc_recommended_min_avg_rxmer_db"),
      flex: 1,
      valueGetter: (value: number) => {
        return value ? value : t("plc_table.no_data");
      }
    },
  ];


  return (
    <Grid xs={12}>
      <DataTable
        rows={data}
        columns={columns}
        pageSize={5}
        totalRows={totalRows}
        checkboxSelection={false}
        // dataTableType="cmtsDetails"
        showSearch={false}
        showExport={false}
      >
        <Box
          sx={{
            display: "flex",
            padding: "5px 0",
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: "8px 8px 0 0",
            // borderBottom: (theme) => `1px solid ${theme.palette.grey}`,
          }}
        >
          <Box sx={{ flexGrow: "1", display: "flex" }}>
            <Box
              sx={{
                paddingLeft: "10px",
                // paddingTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MenuIcon fontSize="small" sx={{ color: "#FEFEFE" }} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: "10px",
                paddingTop: "5px",
                color: "#FEFEFE",
                marginRight: "auto",
              }}
            >
              {t("plc_table.title")}
            </Typography>
          </Box>
        </Box>
      </DataTable>
    </Grid>
  );
}

export function PLCTable({ cmtsId, macDomainIfIndex, channelIfIndex }: PLCTableProps): JSX.Element {
  return (
    <FeedbackContextProvider>
      <PLCTableContent
        cmtsId={cmtsId}
        macDomainIfIndex={macDomainIfIndex}
        channelIfIndex={channelIfIndex}
      />
    </FeedbackContextProvider>
  );
}
