import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GetGrafanaHost } from '../GetEnv';

export type GrafanaPanelProps = {
  url: string;
  width: string;
  height: string;
  from?: number;
  to?: number;
  panelId: number;
  // orgId?: number;
  extraParams?: object;
  injectedClassName?: string;
  injectedStyle?: string;
  noBorder?: boolean;
};

const GrafanaIframe = styled('iframe', {
  shouldForwardProp: prop => {
    return prop !== 'noBorder';
  }
})(({ noBorder }: { noBorder?: boolean }) => {
  if (noBorder) {
    return {
      border: 'none',
      borderRadius: '8px',
      background: 'transparent'
    };
  }

  return {
    border: '0.5px solid #081341',
    borderRadius: '8px'
  };
});

export function GrafanaPanel(props: GrafanaPanelProps): JSX.Element {
  const {
    url,
    width,
    height,
    from,
    to,
    panelId,
    // orgId,
    extraParams,
    injectedClassName,
    injectedStyle,
    noBorder
  } = props;

  const realTo = to ? to : 'now';
  const realFrom = from ? from : `${realTo}-7d`;

  const params = {
    from: realFrom,
    to: realTo,
    panelId: panelId,
    orgId: 1,
    ...extraParams
  };

  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(value => {
    if (Array.isArray(value[1])) {
      value[1].forEach(subValue => {
        searchParams.append(value[0], `${subValue}`);
      });
    } else {
      searchParams.append(value[0], `${value[1]}`);
    }
  });

  const formattedParams = searchParams.toString();
  const grafanaHost = GetGrafanaHost();
  const src = `${grafanaHost}${url}?${formattedParams}`;

  const frame = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(true);

  const onloadCallback = useCallback(() => {
    if (frame === null) {
      return;
    }
    if (frame.current === null) {
      return;
    }

    const doc = frame.current.contentDocument;
    setLoading(false);
    if (doc === null) {
      return;
    }

    const style = doc?.createElement('style');

    if (style && injectedStyle) {
      style.innerHTML = injectedStyle;
      doc?.head?.append(style);
    }

    const body = doc?.querySelector('body');
    if (body) {
      body.className = body.className + ' ' + injectedClassName;
    }
  }, [injectedStyle, injectedClassName, frame, url]);

  useEffect(() => {
    setLoading(true);
    if (frame !== null) {
      frame.current?.addEventListener('load', onloadCallback);
    }

    return () => {
      frame.current?.removeEventListener('load', onloadCallback);
    };
  }, [frame]);

  const memoizedFrame = useMemo((): JSX.Element => {
    return (
      <GrafanaIframe ref={frame} src={src} width={width} height={height} noBorder={noBorder} />
    );
  }, [frame, width, height, noBorder, src]);

  return (
    <>
      <div style={{ display: loading ? 'none' : 'block' }}>{memoizedFrame}</div>
      <div style={{ display: !loading ? 'none' : 'block' }}>
        <GrafanaPlaceholder width={width} height={height} />
      </div>
    </>
  );
}

const GrafanaPlaceholderStyle = styled('div', {
  shouldForwardProp: prop => {
    return prop !== 'width' && prop !== 'height';
  }
})(({ width, height }: { width: string; height: string }) => {
  return {
    border: '0.5px solid #081341',
    borderRadius: '4.10075px',
    padding: '5px',
    width: width,
    height: height
  };
});

export function GrafanaPlaceholder({
  width,
  height
}: {
  width: string;
  height: string;
}): JSX.Element {
  return (
    <GrafanaPlaceholderStyle width={width} height={height}>
      <Skeleton height={30} animation="wave" sx={{ bgcolor: 'grey.300' }} />
      <Skeleton height={30} animation="wave" sx={{ bgcolor: 'grey.300' }} />
      <Skeleton height={30} animation="wave" sx={{ bgcolor: 'grey.300' }} />
    </GrafanaPlaceholderStyle>
  );
}

function CreateHeaderCss(bgColor: string, textColor: string): string {
  return `
div[class*="panel-header"] {
    background: ${bgColor};
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 6px 6px 0 0;
}

.panel-solo > div > div {
  border: none;
}

div[class*="panel-header"] > h6 {
    color: ${textColor};
    font-size: 16px;
    letter-spacing: 0.32px;
    font-weight: 400;
    font-family: 'Encode Sans', Roboto, sans-serif;
    overflow: visible;
    margin: auto;
}

div[class*="panel-header"] > h6:hover {
    color: ${textColor};
}

div[class*="right-aligned-container"] {
    display: none;
}
`;
}

function CreateTableCss(bgColor: string, textColor: string, columnHeaderBgColor: string): string {
  return (
    CreateHeaderCss(bgColor, textColor) +
    `
[role=table] > div > div > div > [role=rowgroup] > [role=row] {
    background: ${textColor};
    color: ${textColor};
}

[role=table] > div > div > div > [role=rowgroup] > [role=row] [role=columnheader] {
    color: ${columnHeaderBgColor};
}
`
  );
}

export const SingleValuePanelStyle = CreateHeaderCss('rgba(8, 26, 81, 0.8)', '#FEFEFE');

export const SingleValuePanelStyleDownstream = CreateHeaderCss('#5C749A', '#FEFEFE');

export const SingleValuePanelStyleUpstream = CreateHeaderCss('#678DB7', '#FEFEFE');

export const GraphPanelStyle = CreateHeaderCss('rgba(8, 26, 81, 0.8)', '#FEFEFE');

export const GraphPanelStyleDownstream = CreateHeaderCss('#5C749A', '#FEFEFE');

export const GraphPanelStyleUpstream = CreateHeaderCss('#678DB7', '#FEFEFE');

export const RankingPanelStyle = CreateTableCss('rgba(8, 26, 81, 0.8)', '#FEFEFE', '#437BE4');

export const RankingPanelStyleDownstream = CreateTableCss('#5C749A', '#FEFEFE', '#437BE4');

export const RankingPanelStyleUpstream = CreateTableCss('#678DB7', '#FEFEFE', '#437BE4');

export function LazyGrafanaPanel(props: GrafanaPanelProps & { loading: boolean }): JSX.Element {
  const { loading, width, height, ...rest } = props;
  if (loading) {
    return <GrafanaPlaceholder width={width} height={height} />;
  }
  return <GrafanaPanel width={width} height={height} {...rest} />;
}
