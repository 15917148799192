import {FeedbackContextProvider, useFeedbackContext} from "../Feedback/FeedbackContext";
import {useTranslation} from "react-i18next";
import {ApiGet} from "../Api/Util";
import {ListChannelResponse} from "../Api/Types/General";
import {ChannelPlcRxmerType} from "../Api/Types/CapacityBooster";
import {ApiRoutes} from "../Api/ApiRoutes";
import React, {useEffect, useState} from "react";
import {GridColDef, GridPaginationModel} from "@mui/x-data-grid-pro";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import DataTable from "../Components/DataTable";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import {Typography} from "@mui/material";

export type ChannelPlcRxmerProp = {
  cmtsTagsParamCollection: number[];
  width?: number;
};

export function ChannelPlcRxmerContent({cmtsTagsParamCollection, width}: ChannelPlcRxmerProp): JSX.Element {
  const { t } = useTranslation();
  const { feedbackError } = useFeedbackContext();

  const [data, setData] = useState<ChannelPlcRxmerType[]>([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    loadData();
  }, [cmtsTagsParamCollection, offset, limit]);

  const loadData = () => {
    ApiGet<ListChannelResponse<ChannelPlcRxmerType>>(
      ApiRoutes.cb.channelPlcRxmer.filter({
        regions: cmtsTagsParamCollection,
        offset: offset,
        limit: limit,
      }),
    )
      .then(async (response: ListChannelResponse<ChannelPlcRxmerType>) => {
        setTotalRows((prevTotalRows) =>
          response?.channels.length !== undefined
            ? response?.channels.length
            : prevTotalRows,
        );
        setData(response.channels);
      })
      .catch((error) => {
        feedbackError(t("channel_plc_rxmer.error"));
      });
  };

  const columns: GridColDef[] = [
    {
        field: 'cmts_name',
        headerName: t("channel_plc_rxmer.cmts_name"),
        flex: 1,
        renderCell: ({ value }) => {
            return value ? value : t("channel_plc_rxmer.no_data");
        }
    },
    {
      field: 'ds_ifdescr',
      headerName: t("channel_plc_rxmer.channel_name"),
      flex: 1,
      renderCell: ({ row }) => {
        const UrlSearchParams = new URLSearchParams();
        const cmtsId = row.cmts_id;
        const macId = row.mac_domain_id;
        const ds_channel_id = row.ds_channel_id;
        UrlSearchParams.append('cmts', cmtsId);
        UrlSearchParams.append('macdomain', macId);
        UrlSearchParams.append('channel', `D${ds_channel_id}`);
        return (
          <Link to={`/rxmer-view/?${UrlSearchParams.toString()}`} target="_blank">
            {row.ds_ifdescr}
          </Link>
        );
      }
    },
    {
      field: 'plc_current_min_avg_rxmer_db',
      headerName: t("channel_plc_rxmer.plc_current_min_avg_rxmer_db"),
      flex: 1,
      renderCell: ({ value }) => {
        return value ? value : t("channel_plc_rxmer.no_data");
      }
    },
    {
      field: 'plc_recommended_min_avg_rxmer_db',
      headerName: t("channel_plc_rxmer.plc_recommended_min_avg_rxmer_db"),
      flex: 1,
      renderCell: ({ value }) => {
        return value ? value : t("channel_plc_rxmer.no_data");
      }
    },
  ];

  const handlePaginationChange = (model: GridPaginationModel) => {
    const page = model.page;
    const limit = model.pageSize;

    setOffset(page * limit);
    setLimit(limit);
  };

  return (
    <Grid xs={width} maxHeight={'480px'}>
      <DataTable
        rows={data}
        columns={columns}
        pageSize={50}
        onPaginationChange={handlePaginationChange}
        totalRows={totalRows}
        checkboxSelection={false}
        dataTableType="channelsRxmer"
        showExport={false}
        showSearch={true}
      >
        <Box
          sx={{
            display: "flex",
            padding: "5px 0",
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: "8px 8px 0 0",
            // borderBottom: (theme) => `1px solid ${theme.palette.grey}`,
          }}
        >
          <Box sx={{ flexGrow: "1", display: "flex" }}>
            <Box
              sx={{
                paddingLeft: "10px",
                // paddingTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MenuIcon fontSize="small" sx={{ color: "#FEFEFE" }} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: "10px",
                paddingTop: "5px",
                color: "#FEFEFE",
                marginRight: "auto",
              }}
            >
              {t("channel_plc_rxmer.title")}
            </Typography>
          </Box>
        </Box>
      </DataTable>
    </Grid>
  );

}

export function ChannelPlcRxmer({cmtsTagsParamCollection}: ChannelPlcRxmerProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <ChannelPlcRxmerContent cmtsTagsParamCollection={cmtsTagsParamCollection} />
    </FeedbackContextProvider>
  );
}
