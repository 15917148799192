import {PmaUsProfile, PmaUsResult} from "../../../../Api/Types/CapacityBooster";
import {Cmts} from "../../../../Api/Types/Config";
import {CmtsUsOfdmaChan} from "../../../../Api/Types/Docis31";
import {MacDomain, UsChannel} from "../../../../Api/Types/Topology";
import {ALLOWED_IUCS, BITLOADING_TO_CBR8_MODULATION, INDENT} from "./Common";
import {BigIntReplacer} from "../../../../Utils/Converters";

export function ConstructCbr8UsInstructions(
  t_no_escape: (key: string, options?: any) => string,
  cmts: Cmts | null,
  macDomain: MacDomain | null,
  usChannel: UsChannel | null,
  pmaUsResult: PmaUsResult | null,
  cmtsUsOfdmaChan: CmtsUsOfdmaChan | null,
  controllerUsChannelId: string,
  includeSafetyChecks: boolean,
  includePmaUsResult: boolean
): string {
  const CBR8_US_CHAN_DESCR_RE
    = /Cable(?<controller_location>\d+\/\d+\/\d+)-upstream(?<upstream_id>\d+)/;
  const UNUSED_US_PROFILE_BITLOADING = 10

  // ====

  const resultsLines: string[] = [];
  resultsLines.push(`# ${t_no_escape("deploy.commands.instructions_title")}`);
  if (pmaUsResult == null || !pmaUsResult.calculated_profiles) {
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.no_profile_data")}`);
    return resultsLines.join("\n");
  }

  if (cmtsUsOfdmaChan == null) {
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.no_cmts_us_ofdma_chan_data")}`);
  }
  const modProfileOfdmaId = cmtsUsOfdmaChan
    ? cmtsUsOfdmaChan.template_index
    : t_no_escape("deploy.commands.cbr8.unknown_mod_profile_ofdma_id");

  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_cmts_mac_domain_us_channel", {
    cmts: pmaUsResult.cmts_name,
    mac_domain: pmaUsResult.md_ifdescr,
    us_channel: pmaUsResult.us_ifdescr
  })}`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_pma_task_id_us_result_id", {
    pma_task_id: pmaUsResult.task,
    pma_us_result_id: pmaUsResult.id
  })}`);

  let timestamp = pmaUsResult.result_completed_timestamp;
  timestamp = timestamp.replace("T", " ").replace("Z", " UTC");
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.profiles_calculated_at_timestamp", {
    timestamp: timestamp
  })}`);

  // get cmts-specific ids
  let us_chan_info = pmaUsResult.us_ifdescr.match(CBR8_US_CHAN_DESCR_RE)?.groups;
  if (!us_chan_info) {
    us_chan_info = {
      controller_location: t_no_escape("deploy.commands.cbr8.unknown_controller_location"),
      upstream_id: t_no_escape("deploy.commands.cbr8.unknown_upstream_id"),
    };
  }
  const controllerLocation = us_chan_info.controller_location;
  const upstreamId = us_chan_info.upstream_id;

  const iucToPmaUsProfile: { [p: number]: PmaUsProfile | null } = {};
  ALLOWED_IUCS.forEach(iuc => {
    iucToPmaUsProfile[iuc] = null;
  });
  pmaUsResult.calculated_profiles.forEach(pmaUsProfile => {
    iucToPmaUsProfile[pmaUsProfile.data_iuc] = pmaUsProfile;
  });

  if (includeSafetyChecks) {

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.safety_check_which_channel_intro", {
      controller_location: controllerLocation,
      controller_us_channel_id: controllerUsChannelId,
      us_if_descr: pmaUsResult.us_ifdescr
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_which_channel_do_command")}`);
    resultsLines.push(`show controllers Upstream-Cable ${controllerLocation} us-channel ${controllerUsChannelId} | include ind to`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_which_channel_check", {
      controller_location: controllerLocation,
      upstream_id: upstreamId,
    })}`);

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.safety_check_channel_config_intro", {
      controller_location: controllerLocation,
      controller_us_channel_id: controllerUsChannelId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_channel_config_do_command")}`);
    resultsLines.push(`show running-config controller Upstream-Cable ${controllerLocation} | include us-channel ${controllerUsChannelId}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_channel_config_check_mode", {
      controller_us_channel_id: controllerUsChannelId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_channel_config_check_profile", {
      controller_us_channel_id: controllerUsChannelId,
      mod_profile_ofdma_id: modProfileOfdmaId
    })}`);
    // check: channel frequency range matches new profiles?
    // check: subcarrier-spacing?

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.safety_check_mod_profile_intro", {
      mod_profile_ofdma_id: modProfileOfdmaId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_mod_profile_do_command")}`);
    resultsLines.push(`show running-config | include modulation-profile ${modProfileOfdmaId}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.safety_check_mod_profile_check")}`);
  }

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.begin_configuration_heading")}`);

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.clear_existing_exception_zones_heading")}`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.clear_existing_exception_zones_do_command")}`);
  resultsLines.push(`show running-config controller Upstream-Cable ${controllerLocation} | include us-channel ${controllerUsChannelId} data-iuc`);
  resultsLines.push(`configure terminal`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.clear_existing_exception_zones_for_each")}`);
  resultsLines.push(`# ${INDENT}${INDENT}controller Upstream-Cable ${controllerLocation}`);
  resultsLines.push(`# ${INDENT}${INDENT}${INDENT}no us-channel ${controllerUsChannelId} data-iuc 11 band 24700000 25100000 modulation 2048-QAM pilot-pattern 4`);
  resultsLines.push(`# ${INDENT}${INDENT}exit`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.cbr8.clear_existing_exception_zones_check")}`);

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.define_default_modulations_heading")}`);

  // determine values for unused IUCs
  let unused_pilot_pattern: number | null = null;
  ALLOWED_IUCS.forEach(iuc => {
    const pmaUsProfile = iucToPmaUsProfile[iuc];
    if (pmaUsProfile) {
      unused_pilot_pattern = pmaUsProfile.default_pilot_pattern;
    }
  });
  const unused_modulation: string = BITLOADING_TO_CBR8_MODULATION[UNUSED_US_PROFILE_BITLOADING];

  // update default modulations
  ALLOWED_IUCS.forEach(iuc => {
    const pmaUsProfile = iucToPmaUsProfile[iuc];
    let modulation = unused_modulation;
    let pilot_pattern = unused_pilot_pattern;
    if (pmaUsProfile) {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.cbr8.configure_data_iuc_default_bit_loading_mean_bit_loading",
        {
          iuc: iuc,
          default_bit_loading: pmaUsProfile.default_bit_loading,
          mean_bit_loading: pmaUsProfile.mean_subcarrier_bit_loading
        })}`);
      modulation = BITLOADING_TO_CBR8_MODULATION[pmaUsProfile.default_bit_loading];
      pilot_pattern = pmaUsProfile.default_pilot_pattern;
    } else {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.cbr8.configure_data_iuc_unused",
        {iuc: iuc})}`);
    }
    resultsLines.push(`cable mod-profile-ofdma ${modProfileOfdmaId}`);
    resultsLines.push(`${INDENT}data-iuc ${iuc}`
      + ` modulation ${modulation}`
      + ` pilot-pattern ${pilot_pattern}`);
    resultsLines.push(`exit`);
  });

  // define exception zones
  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.define_exception_zones_heading")}`);
  ALLOWED_IUCS.forEach(iuc => {
    const pmaUsProfile = iucToPmaUsProfile[iuc];
    if (!pmaUsProfile) {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.cbr8.configure_data_iuc_unused",
        {iuc: iuc})}`);
      return;
    }
    if (pmaUsProfile.us_subcarrier_statuses.length > 1) {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.cbr8.configure_data_iuc_default_bit_loading_mean_bit_loading",
        {
          iuc: iuc,
          default_bit_loading: pmaUsProfile.default_bit_loading,
          mean_bit_loading: pmaUsProfile.mean_subcarrier_bit_loading
        })}`);
      pmaUsProfile.us_subcarrier_statuses.forEach(pmaUsSubcarrierStatus => {
        if (pmaUsSubcarrierStatus.minislot_bit_loading == pmaUsProfile.default_bit_loading) {
          return;
        }
        const modulation = BITLOADING_TO_CBR8_MODULATION[pmaUsSubcarrierStatus.minislot_bit_loading];
        resultsLines.push(`controller Upstream-Cable ${controllerLocation}`);
        resultsLines.push(
          `${INDENT}us-channel ${controllerUsChannelId}`
          + ` data-iuc ${iuc}`
          + ` band ${pmaUsSubcarrierStatus.start_frequency_hz} ${pmaUsSubcarrierStatus.end_frequency_hz}`
          + ` modulation ${modulation}`
          + ` pilot-pattern ${pmaUsSubcarrierStatus.minislot_pilot_pattern}`
        );
        resultsLines.push(`exit`);
      });
    } else {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.cbr8.configure_data_iuc_default_bit_loading_mean_bit_loading_no_exceptions",
        {
          iuc: iuc,
          default_bit_loading: pmaUsProfile.default_bit_loading,
          mean_bit_loading: pmaUsProfile.mean_subcarrier_bit_loading
        })}`);
    }
  });

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.cbr8.configuration_complete_heading")}`);
  resultsLines.push("exit");

  // output debug info if requested
  if (includePmaUsResult) {
    resultsLines.push("");
    resultsLines.push(`# pmaUsResult: ${JSON.stringify(pmaUsResult, BigIntReplacer)}`);
  }

  return resultsLines.join("\n");
}
