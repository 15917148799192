import { Box, Breadcrumbs, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApiRoutes } from '../Api/ApiRoutes';
import { ApiGet, ChannelDirection, isListApiResponse } from '../Api/Util';
import { PmaUsResult } from '../Api/Types/CapacityBooster';
import { Cmts, TenantSchedule } from '../Api/Types/Config';
import { ListApiResponse } from '../Api/Types/General';
import { MacDomain, UsChannel } from '../Api/Types/Topology';

import {
  GlobalConfigContextProvider,
  handleTenantScheduleQuery,
  useGlobalConfigContext
} from '../Configuration/GlobalStateContext';

import { FeedbackContextProvider, useFeedbackContext } from '../Feedback/FeedbackContext';

import { castToNumber } from './CastToNumber';
import { ActionButtonBar, DeployCalculatedProfiles, RollBackProfiles } from './Channel/ActionButtonBar';
import { ChannelDeploymentContext } from './Channel/DeploymentContext';
import { ProfileSetDisplay } from './Channel/ProfileSetDisplay';
import { TimeRemainingBar } from './Channel/TimeRemainingBar';
import { NoChannelInfo } from './NoChannelInfo';
import { ManualProfileDeployment } from './Channel/ManualDeployment/ManualProfileDeployment';

import { useGrafanaLink } from '../Dashboard/Dashboard';
import CircularProgress from '@mui/material/CircularProgress';

export function UpstreamChannelDeploymentComponent(): JSX.Element {
  const { t } = useTranslation();
  const { setGrafanaLinkButton } = useGrafanaLink();

  const { cmtsId, macDomainIfIndex, channelIfIndex } = useParams();
  const cmtsIdNum = castToNumber(cmtsId, 0);
  const macDomainIfIndexNum = castToNumber(macDomainIfIndex, 0);
  const channelIfIndexNum = castToNumber(channelIfIndex, 0);

  const [cmtsInfo, setCmtsInfo] = useState<Cmts | null>(null);
  const [macDomain, setMacDomain] = useState<MacDomain | null>(null);
  const [usChannel, setUsChannel] = useState<UsChannel | null>(null);
  const [actionsEnabled, setActionsEnabled] = useState<boolean>(false);
  const [data, setData] = useState<PmaUsResult[]>();
  const [pmaUsResult, setPmaUsResult] = useState<PmaUsResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { setTenantScheduleState } = useGlobalConfigContext();
  const { feedbackError } = useFeedbackContext();

  useEffect(() => {
    setLoading(true);
    ApiGet<ListApiResponse<PmaUsResult>>(
      ApiRoutes.cb.usProfileSet.filter({
        latest: true,
        cmtsId: cmtsIdNum,
        macDomainIfIndex: macDomainIfIndexNum,
        channelIfIndex: channelIfIndexNum
      })
    ).then(async (response: ListApiResponse<PmaUsResult>) => {
      setLoading(false);
      setData(response.results);
      if (isListApiResponse<PmaUsResult>(response.results) && response.count == 1) {
        setPmaUsResult(response.results[0]);
      } else {
        setPmaUsResult(null);
      }
    });
  }, [cmtsIdNum, macDomainIfIndexNum, channelIfIndexNum]);

  useEffect(() => {
    ApiGet<Cmts>(ApiRoutes.config.cmts.single(cmtsIdNum))
      .then(result => {
        setCmtsInfo(result);
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.config.cmts',
          cmtsIdNum: cmtsIdNum,
          error: error
        });
        feedbackError(t('deploy.failed_fetching_Cmts', { message: error.message }));
      });
  }, [cmtsIdNum, setCmtsInfo]);

  useEffect(() => {
    ApiGet<ListApiResponse<MacDomain>>(
      ApiRoutes.topology.mac_domain.filter({
        cmtsId: cmtsIdNum,
        macDomainIfIndex: macDomainIfIndexNum,
        latest: true
      })
    )
      .then(result => {
        if (result.count) {
          setMacDomain(result.results[0]);
        }
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.topology.mac_domain',
          cmtsId: cmtsIdNum,
          macDomainIfIndex: macDomainIfIndexNum,
          latest: true,
          error: error
        });
        feedbackError(t('deploy.failed_fetching_MacDomain', { message: error.message }));
      });
  }, [cmtsIdNum, macDomainIfIndexNum, setMacDomain]);

  useEffect(() => {
    ApiGet<ListApiResponse<UsChannel>>(
      ApiRoutes.topology.us_channel.filter({
        cmtsId: cmtsIdNum,
        macDomainIfIndex: macDomainIfIndexNum,
        channelIfIndex: channelIfIndexNum,
        latest: true
      })
    )
      .then(result => {
        if (result.count) {
          setUsChannel(result.results[0]);
        }
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.topology.us_channel',
          cmtsId: cmtsIdNum,
          macDomainIfIndex: macDomainIfIndexNum,
          channelIfIndex: channelIfIndexNum,
          latest: true,
          error: error
        });
        feedbackError(t('deploy.failed_fetching_UsChannel', { message: error.message }));
      });
  }, [cmtsIdNum, macDomainIfIndexNum, channelIfIndexNum, setUsChannel]);

  useEffect(() => {
    ApiGet<ListApiResponse<TenantSchedule>>(ApiRoutes.config.schedule.all)
      .then(async (response: ListApiResponse<TenantSchedule>) => {
        handleTenantScheduleQuery(response, setTenantScheduleState);
      })
      .catch(error => {
        console.log({
          route: 'ApiRoutes.config.schedule',
          error: error
        });
        feedbackError(t('deploy.failed_fetching_TenantSchedule', { message: error.message }));
      });
  }, []);

  useEffect(() => {
    setGrafanaLinkButton(false);
  }, []);

  return (
    <ChannelDeploymentContext.Provider
      value={{
        cmts: cmtsInfo,
        macDomain: macDomain,
        dsChannel: null,
        usChannel: usChannel,
        pmaDsResult: null,
        pmaUsResult: pmaUsResult,
        pageState: {
          actionsEnabled: actionsEnabled,
          setActionsEnabled: setActionsEnabled
        }
      }}
    >
      {loading ? (
        <Box display="flex" justifyContent="center" ><CircularProgress/></Box>
      ) : data === null || pmaUsResult === null ? (
        <NoChannelInfo />
      ) : (
        <FeedbackContextProvider>
          <Breadcrumbs separator="›" sx={{ marginBottom: '20px' }}>
            {/* TODO: These will be links in future builds */}
            <Typography color={(theme): string => theme.colors.inactive.main}>CMTS List</Typography>
            <Typography color={(theme): string => theme.colors.inactive.main}>
              {pmaUsResult.cmts_name}
            </Typography>{' '}
            {/* Link to {cmtsId} */}
            <Typography color={(theme): string => theme.colors.inactive.main}>
              {pmaUsResult.md_ifdescr}
            </Typography>{' '}
            {/* Link to {macDomainId} */}
            <Typography color="inherit">{pmaUsResult.us_ifdescr}</Typography>{' '}
            {/* Link to {channelId} */}
          </Breadcrumbs>

          <ActionButtonBar>
            {/*<PageFilters />*/}
            <DeployCalculatedProfiles channel_direction={ChannelDirection.Upstream} />
            <RollBackProfiles channel_direction={ChannelDirection.Upstream} />
          </ActionButtonBar>

          <Grid2 container spacing={2} columns={12}>
            <TimeRemainingBar downstream={false} />
            <ManualProfileDeployment cmtsInfo={cmtsInfo} downstream={false} />
            <ProfileSetDisplay pmaResult={pmaUsResult} downstream={false} />
          </Grid2>
        </FeedbackContextProvider>
      )}
    </ChannelDeploymentContext.Provider>
  );
}

export function UpstreamChannelDeployment(): JSX.Element {
  return (
    <GlobalConfigContextProvider>
      <UpstreamChannelDeploymentComponent />
    </GlobalConfigContextProvider>
  );
}
