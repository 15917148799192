import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CmtsConfigPanel } from './CmtsConfigPanel';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { BsPlusLg } from 'react-icons/bs';
import { AddCmtsDialog } from './AddCmtsDialog';
import { Cmts } from '../../Api/Types/Config';
import { ListApiResponse } from '../../Api/Types/General';
import { ApiGet } from '../../Api/Util';

export function GlobalCmtsConfiguration(): JSX.Element {
  const { t } = useTranslation();
  const [cmtsData, setCmtsData] = useState<Cmts[]>([]);
  const [addCmtsOpen, setAddCmtsOpen] = useState<boolean>(false);

  const updateCmtsList = useCallback(() => {
    ApiGet<ListApiResponse<Cmts>>(ApiRoutes.config.cmts.filter({
      limit: 1000
    }))
      .then(async (response: ListApiResponse<Cmts>) => {
      setCmtsData(response.results);
    });
  }, [setCmtsData]);

  useEffect(updateCmtsList, [updateCmtsList]);

  const addCmtsCallback = useCallback(() => {
    setAddCmtsOpen(true);
  }, [setAddCmtsOpen]);

  const cmtsPanels: JSX.Element = useMemo((): JSX.Element => {
    return (
      <>
        {cmtsData.map((cmts: Cmts) => (
          <Grid key={cmts.id} item xs={12}>
            <CmtsConfigPanel cmts={cmts} updateCmtsList={updateCmtsList} />
          </Grid>
        ))}
      </>
    );
  }, [cmtsData, updateCmtsList]);

  return (
    <>
      <Grid container spacing={2}>
        {cmtsPanels}
        <Grid item xs={12}>
          <Button
            variant="contained"
            startIcon={<BsPlusLg />}
            onClick={addCmtsCallback}
            data-testid="add_cmts"
          >
            <Typography variant="h6">{t('cmts_config.add_cmts')}</Typography>
          </Button>
        </Grid>
      </Grid>
      <AddCmtsDialog open={addCmtsOpen} setOpen={setAddCmtsOpen} updateCmtsList={updateCmtsList} />
    </>
  );
}
