import {PmaUsProfile, PmaUsResult} from "../../../../Api/Types/CapacityBooster";
import {Cmts} from "../../../../Api/Types/Config";
import {MacDomain, UsChannel} from "../../../../Api/Types/Topology";
import {ALLOWED_IUCS, BITLOADING_TO_E6K_US_MODULATION, INDENT, PilotPattern} from "./Common";
import {BigIntReplacer} from "../../../../Utils/Converters";

export function ConstructE6kUsInstructions(
  t_no_escape: (key: string, options?: any) => string,
  cmts: Cmts | null,
  macDomain: MacDomain | null,
  usChannel: UsChannel | null,
  pmaUsResult: PmaUsResult | null,
  ofdmModProfId: string,
  includeSafetyChecks: boolean,
  includePmaUsResult: boolean
): string {
  const E6K_US_CHAN_DESCR_RE
    = /cable-us-ofdma (?<interfaceCableUsId>\d+\/\d+\/\d+)\.0/;
  const E6K_US_UNUSED_PROFILE_BITLOADING = 10;

  // ====

  const resultsLines: string[] = [];
  resultsLines.push(`# ${t_no_escape("deploy.commands.instructions_title")}`);
  if (pmaUsResult == null || !pmaUsResult.calculated_profiles) {
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.no_profile_data")}`);
    return resultsLines.join("\n");
  }

  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_cmts_mac_domain_us_channel", {
    cmts: pmaUsResult.cmts_name,
    mac_domain: pmaUsResult.md_ifdescr,
    us_channel: pmaUsResult.us_ifdescr
  })}`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_pma_task_id_us_result_id", {
    pma_task_id: pmaUsResult.task,
    pma_us_result_id: pmaUsResult.id
  })}`);

  let timestamp = pmaUsResult.result_completed_timestamp;
  timestamp = timestamp.replace("T", " ").replace("Z", " UTC");
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.profiles_calculated_at_timestamp", {
    timestamp: timestamp
  })}`);

  // get cmts-specific ids
  let us_chan_info = pmaUsResult.us_ifdescr.match(E6K_US_CHAN_DESCR_RE)?.groups;
  if (!us_chan_info) {
    us_chan_info = {
      interfaceCableUsId: t_no_escape("deploy.commands.e6k.unknown_interface_cable_us_id"),
    };
  }
  const interfaceCableUsId = us_chan_info.interfaceCableUsId;

  const iucToPmaUsProfile: { [p: number]: PmaUsProfile | null } = {};
  ALLOWED_IUCS.forEach(iuc => {
    iucToPmaUsProfile[iuc] = null;
  });
  pmaUsResult.calculated_profiles.forEach(pmaUsProfile => {
    iucToPmaUsProfile[pmaUsProfile.data_iuc] = pmaUsProfile;
  });

  // determine values for unused IUCs
  const unusedProfileModulation = BITLOADING_TO_E6K_US_MODULATION[E6K_US_UNUSED_PROFILE_BITLOADING];
  let unusedProfilePilotPattern: PilotPattern = 0;
  ALLOWED_IUCS.forEach(iuc => {  // use pilot pattern from fastest IUC
    const pmaUsProfile = iucToPmaUsProfile[iuc];
    if (pmaUsProfile) {
      unusedProfilePilotPattern = pmaUsProfile.default_pilot_pattern;
    }
  });

  if (includeSafetyChecks) {

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.safety_check_upstream_channel_intro", {
      interface_cable_us_id: interfaceCableUsId,
      ofdm_mod_prof_id: ofdmModProfId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_upstream_channel_do_command")}`);
    resultsLines.push(`show running-config interface cable-upstream ${interfaceCableUsId}`
      + ` | include ofdm modulation-profile`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_upstream_channel_check_one", {
      interface_cable_us_id: interfaceCableUsId,
      ofdm_mod_prof_id: ofdmModProfId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_upstream_channel_check_id", {
      interface_cable_us_id: interfaceCableUsId,
      ofdm_mod_prof_id: ofdmModProfId
    })}`);

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.safety_check_mod_profile_intro", {
      interface_cable_us_id: interfaceCableUsId,
      ofdm_mod_prof_id: ofdmModProfId
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_mod_profile_do_command")}`);
    resultsLines.push(`show running-config | include ofdm modulation-profile ${ofdmModProfId}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_mod_profile_check_line")}`);

    // check: channel frequency range matches new profiles?
    // check: subcarrier-spacing?
  }

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.begin_configuration_heading")}`);

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.clear_existing_exception_zones_heading")}`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.clear_existing_exception_zones_do_command")}`);
  resultsLines.push(`show running-config interface cable-upstream ${interfaceCableUsId} verbose`
    + ` | include ofdm iuc`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.clear_existing_exception_zones_for_each")}`);
  resultsLines.push(`# ${INDENT}${INDENT}configure interface cable-upstream ${interfaceCableUsId} ofdm iuc 5 low-freq-edge 25675000 high-freq-edge 28925000 modulation 512qam pilot-pattern 2 no`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.clear_existing_exception_zones_check")}`);

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.define_default_modulations_heading")}`);
  ALLOWED_IUCS.forEach(iuc => {
    const pmaUsProfile = iucToPmaUsProfile[iuc];
    if (pmaUsProfile) {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.e6k.configure_data_iuc_default_bit_loading_mean_bit_loading",
        {
          iuc: iuc,
          default_bit_loading: pmaUsProfile.default_bit_loading,
          mean_bit_loading: pmaUsProfile.mean_subcarrier_bit_loading
        })}`);
      const modulation = BITLOADING_TO_E6K_US_MODULATION[pmaUsProfile.default_bit_loading];
      resultsLines.push(`configure ofdm modulation-profile ${ofdmModProfId} iuc ${iuc}`
        + ` modulation ${modulation}`);
      resultsLines.push(`configure ofdm modulation-profile ${ofdmModProfId} iuc ${iuc}`
        + ` pilot-pattern ${pmaUsProfile.default_pilot_pattern}`);
    } else {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.e6k.configure_data_iuc_unused",
        {iuc: iuc})}`);
      // resultsLines.push(`configure ofdm modulation-profile ${ofdmModProfId} iuc ${iuc} no`);
      resultsLines.push(`configure ofdm modulation-profile ${ofdmModProfId} iuc ${iuc}`
        + ` modulation ${unusedProfileModulation}`);
      resultsLines.push(`configure ofdm modulation-profile ${ofdmModProfId} iuc ${iuc}`
        + ` pilot-pattern ${unusedProfilePilotPattern}`);
    }
  });

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.define_exception_zones_heading")}`);
  ALLOWED_IUCS.forEach(iuc => {
    const pmaUsProfile = iucToPmaUsProfile[iuc];
    if (!pmaUsProfile) {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.e6k.configure_data_iuc_unused",
        {iuc: iuc})}`);
      return;
    }
    if (pmaUsProfile.us_subcarrier_statuses.length > 1) {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.e6k.configure_data_iuc_default_bit_loading_mean_bit_loading",
        {
          iuc: iuc,
          default_bit_loading: pmaUsProfile.default_bit_loading,
          mean_bit_loading: pmaUsProfile.mean_subcarrier_bit_loading
        })}`);
      pmaUsProfile.us_subcarrier_statuses.forEach(pmaUsSubcarrierStatus => {
        if (pmaUsSubcarrierStatus.minislot_bit_loading == pmaUsProfile.default_bit_loading) {
          return;
        }
        const modulation = BITLOADING_TO_E6K_US_MODULATION[pmaUsSubcarrierStatus.minislot_bit_loading];
        resultsLines.push(
          `configure interface cable-upstream ${interfaceCableUsId} ofdm iuc ${iuc}`
          + ` low-freq-edge ${pmaUsSubcarrierStatus.start_frequency_hz}`
          + ` high-freq-edge ${pmaUsSubcarrierStatus.end_frequency_hz}`
          + ` modulation ${modulation} pilot-pattern ${pmaUsSubcarrierStatus.minislot_pilot_pattern}`
        );
      });
    } else {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.e6k.configure_data_iuc_default_bit_loading_mean_bit_loading_no_exceptions",
        {
          iuc: iuc,
          default_bit_loading: pmaUsProfile.default_bit_loading,
          mean_bit_loading: pmaUsProfile.mean_subcarrier_bit_loading
        })}`);
    }
  });

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.configuration_complete_heading")}`);

  // output debug info if requested
  if (includePmaUsResult) {
    resultsLines.push("");
    resultsLines.push(`# pmaUsResult: ${JSON.stringify(pmaUsResult, BigIntReplacer)}`);
  }

  return resultsLines.join("\n");
}
