export const NavRoutes = {
  home: "/",
  login: "/login",
  reports: "/reports/",
  data: "/data/",
  config: "/configuration/",
  users: "/users/",
  account: "/account/",
  manage_users: "/manage-users/",
  help: "/help/",
  care: "/care/",
  overview: "/overview",
  deployment: {
    home: "/deployment/",
    cmts: {
      route: "/deployment/:cmtsId/",
      param: (cmtsId: number | bigint): string => `/deployment/${cmtsId}/`
    },
    macDomain: {
      route: "/deployment/:cmtsId/:macDomainId/",
      param: (cmtsId: number | bigint, madDomainIfIndex: number | bigint): string =>
        `/deployment/${cmtsId}/${madDomainIfIndex}/`,
    },
    ds_channel: {
      route: "/deployment/:cmtsId/:macDomainIfIndex/ds/:channelIfIndex/",
      param: (cmtsId: number | bigint, madDomainIfIndex: number | bigint, channelIfIndex: number | bigint): string =>
        `/deployment/${cmtsId}/${madDomainIfIndex}/ds/${channelIfIndex}/`,
    },
    us_channel: {
      route: "/deployment/:cmtsId/:macDomainIfIndex/us/:channelIfIndex/",
      param: (cmtsId: number | bigint, madDomainIfIndex: number | bigint, channelIfIndex: number | bigint): string =>
        `/deployment/${cmtsId}/${madDomainIfIndex}/us/${channelIfIndex}/`,
    },
  },
  downstream: "/downstream/",
  tech_supervisor: "/tech-supervisor/",
  channel_view: "/channel-view/",
  operational_cm: "/operational-cm/",
  rxmer_view: "/rxmer-view/"
};
