import { useCallback, useEffect, useState } from 'react';
import { ApiRoutes } from '../Api/ApiRoutes';
import { useFeedbackContext } from '../Feedback/FeedbackContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

export enum SearchItemType {
  Mac = 'MAC',
  DsChannel = 'Downstream Channel',
  UsChannel = 'Upstream Channel'
}

export type MacMeta = {
  cmts: string;
  mac_domain: string;
};

export type ChannelMeta = {
  cmts: string;
  mac_domain: string;
};

export type SearchItem = {
  id: number;
  itemType: SearchItemType;
  search: string;
  title: string;
  detail: string;
  meta: MacMeta | ChannelMeta;
};

export type SearchDataResult = {
  time_start: string;
  time_end: string;
  data: SearchItem[];
};

const Urls = {
  OperationalCm: '/grafana/d/lXsaTFwnz/operational-cm',
  OperationalDsChannel: '/grafana/d/pkpIPDl7k/operational-downstream-channel-rxmer',
  OperationalUsChannel: '/grafana/d/Os6m0A_nk/operational-upstream-channel-rxmer'
};

function FormatUrl(searchItem: SearchItem): string {
  if(!searchItem) {
    return '';
  }
  switch (searchItem.itemType) {
    case SearchItemType.Mac:
      return FormatMacUrl(searchItem);
    default:
    // TODO error
  }
  return '';
}

function encodeParams(parameters: object): string {
  return Object.entries(parameters)
    .map(([key, value]) => {
      if (!value) {
        return `${encodeURIComponent(key)}=`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
}

function FormatMacUrl(searchItem: SearchItem): string {
  const parameters = {
    // orgId: 1,
    'var-cmts': searchItem.meta.cmts,
    'var-mac_dom': searchItem.meta.mac_domain,
    'var-mac_addr': searchItem.title
    // "var-ds_chan": searchItem.meta.ds_chan,
    // "var-us_chan": searchItem.meta.us_chan,
    // "var-ds_rxmer_timestamp": null,
    // "var-us_rxmer_timestamp": null,
    // "from": from,
    // "to": to,
  };

  return `${Urls.OperationalCm}?${encodeParams(parameters)}`;
}

export function Search({ sx, color }: { sx?: any; color?: string }): JSX.Element {
  const [searchData, setSearchData] = useState<SearchItem[]>([]);
  const { setFeedbackState } = useFeedbackContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(ApiRoutes.pma.search, {
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8'
      }
    }).then(async (response: Response) => {
      if (!response.ok) {
        setFeedbackState({
          open: true,
          message: `${t('search.failed_search')}: ${response.statusText}`,
          severity: 'error'
        });

        return;
      }

      const result: SearchDataResult = await response.json();
      setSearchData(result.data.filter(item => item.title.includes(':')));
    });
  }, []);

  const handleOnSelect = useCallback(
    (item: SearchItem): void => {
      const newUrl = FormatUrl(item);
      if(!item){
        return;
      }
      switch (item.itemType) {
        case SearchItemType.Mac:
          window.open(newUrl, '_blank');
          break;
        default:
          navigate(newUrl);
      }
    },
    [navigate]
  );

  return (
    <Autocomplete
      freeSolo
      id="search"
      data-testid="search-input"
      // disableClearable
      options={searchData}
      // open={true}
      onChange={(event: any, value: any) => {
        handleOnSelect(value);
      }}
      getOptionLabel={(option: string | SearchItem) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.title;
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ display: "flex", flexDirection: "column", alignItems: "start !important", backgroundColor: '#f8f9ff' }} {...props}>
          <span style={{ textAlign: 'left' }}>
            {option.itemType}: <strong>{option.title}</strong>
          </span>
          <span
            style={{ textAlign: 'left',
            color: '#555555'
          }}
          >
            <small>
              <em>{option.detail}</em>
            </small>
          </span>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t('search.placeholder')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
                {params.InputProps.endAdornment}
              </InputAdornment>
            )
          }}
          sx={{
            color: '#555555',
            backgroundColor: '#f8f9ff',
            borderRadius: '30px',
            fontFamily: 'Encode Sans, sans-serif',
            fontSize: '14px'
          }}
        />
      )}
      sx={{
        width: '50%',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(33, 49, 98, 0.6)',
          borderWidth: '0.5px',
          borderRadius: '30px'
        },
        '& .MuiAutocomplete-paper': {
          borderRadius: '30px'
        }
      }}
    />
  );
}
