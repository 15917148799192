import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useChannelDeploymentContext } from './DeploymentContext';
import { useCallback, useMemo } from 'react';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { ApiPost, ChannelDirection } from '../../Api/Util';


export type DeployProfilesResponse = {
  status: string;
  trace_id: bigint;
};

export function DeployCalculatedProfiles({
  channel_direction,
}: {
  channel_direction: ChannelDirection;
}): JSX.Element {
  const { t } = useTranslation();
  const { pmaDsResult, pmaUsResult } = useChannelDeploymentContext();
  const { setFeedbackState } = useFeedbackContext();

  const handleClick = useCallback(() => {
    const url =
      channel_direction == ChannelDirection.Downstream
        ? ApiRoutes.cb.deployDsCalculatedProfiles
        : ApiRoutes.cb.deployUsCalculatedProfiles;
    const data =
      channel_direction == ChannelDirection.Downstream
        ? { ds_result_id: pmaDsResult?.id }
        : { us_result_id: pmaUsResult?.id };

    ApiPost<DeployProfilesResponse>(url, data)
      .then((value: DeployProfilesResponse) => {
        setFeedbackState({
          open: true,
          severity: "success",
          message: t("deploy.deploy_calc_to_channel_success"),
        });
      })
      .catch((reason) => {
        setFeedbackState({
          open: true,
          severity: "error",
          message: reason.message,
        });
      });
  }, [channel_direction, setFeedbackState, pmaDsResult, pmaUsResult]);

  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));
  const disabled = useMemo(
    () =>
      channel_direction === ChannelDirection.Downstream
        ? !pmaDsResult
        : !pmaUsResult,
    [channel_direction, pmaUsResult, pmaDsResult],
  );

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleClick}
      sx={{
        borderRadius: "1.5px",
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)",
        marginRight: matchMD ? "0" : "15px",
      }}
      disabled={disabled}
    >
      {t("deploy.deploy_calc_to_channel")}
    </Button>
  );
}

export function RollBackProfiles({
  channel_direction,
}: {
  channel_direction: ChannelDirection;
}): JSX.Element {
  const { t } = useTranslation();
  const { dsChannel, usChannel } = useChannelDeploymentContext();
  const { setFeedbackState } = useFeedbackContext();

  const handleClick = useCallback(() => {
    const url =
      channel_direction == ChannelDirection.Downstream
        ? ApiRoutes.cb.rollBackDsProfiles
        : ApiRoutes.cb.rollBackUsProfiles;
    const data =
      channel_direction == ChannelDirection.Downstream
        ? { channel_id: dsChannel?.channel_id }
        : { channel_id: usChannel?.channel_id };

    ApiPost<DeployProfilesResponse>(url, data)
      .then((value: DeployProfilesResponse) => {
        setFeedbackState({
          open: true,
          severity: "success",
          message: t("deploy.roll_back_profiles_success"),
        });
      })
      .catch((reason) => {
        setFeedbackState({
          open: true,
          severity: "error",
          message: reason.message,
        });
      });
  }, [setFeedbackState, channel_direction, dsChannel, usChannel]);

  const disabled = useMemo(
    () =>
      channel_direction === ChannelDirection.Downstream
        ? !dsChannel
        : !usChannel,
    [channel_direction, dsChannel, usChannel],
  );

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleClick}
      sx={{
        borderRadius: "1.5px",
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)",
      }}
      disabled={disabled}
    >
      {t("deploy.roll_back_profiles")}
    </Button>
  );
}

type ActionButtonBarProps = {
  children: JSX.Element[] | JSX.Element | null;
};

export function ActionButtonBar({
  children,
}: ActionButtonBarProps): JSX.Element {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        marginBottom: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: matchMD ? "5px" : "",
      }}
    >
      {children}
    </Box>
  );
}
