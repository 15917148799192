import { FeedbackContextProvider, useFeedbackContext } from '../Feedback/FeedbackContext';
import React, { useEffect, useState } from 'react';
import { ApiGet } from '../Api/Util';
import { ListApiResponse } from '../Api/Types/General';
import { ApiRoutes } from '../Api/ApiRoutes';
import {
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColDef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridTreeNodeWithRender,
  useGridSelector
} from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import DataTable from '../Components/DataTable';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { ChannelActivityType } from '../Api/Types/CapacityBooster';
import { NavRoutes } from '../Navigation/NavRoutes';
import {
  ChannelActivityButtonBar,
  ChannelActivityDeployCalculatedProfiles,
  ChannelActivityRollBackProfiles,
  ChannelActivityScheduleAutomatedProfile,
  ChannelActivityDisableAutomatedProfile
} from './ChannelActivityButtonBar';
import { ChannelActivityManualDeployment } from './ChannelActivityManualDeployment';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DateFormatted} from "../Utils/Converters";

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest
            })
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

export type ChannelActivityContentProp = {
  cmtsId: string;
  macDomainIfIndex: string;
  channelIfIndex: string;
};

export function ChannelActivityContent({
  cmtsId,
  macDomainIfIndex,
  channelIfIndex
}: ChannelActivityContentProp): JSX.Element {
  const { t } = useTranslation();
  const { feedbackError } = useFeedbackContext();

  const [channelActivityData, setChannelActivityData] = React.useState<ChannelActivityType[]>([]);
  const [multipleSelectedChannels, setMultipleSelectedChannels] = useState<ChannelActivityType[]>(
    []
  );
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  useEffect(() => {
    loadData();
  }, [cmtsId, macDomainIfIndex, channelIfIndex, offset, limit]);
  const loadData = () => {
    ApiGet<ListApiResponse<ChannelActivityType>>(
      ApiRoutes.cb.channelActivity.filter({
        latest: true,
        cmtsId: Number(cmtsId),
        macDomainIfIndex: Number(macDomainIfIndex),
        channelIfIndex: Number(channelIfIndex),
        limit: limit,
        offset: offset
      })
    )
      .then(async (response: ListApiResponse<ChannelActivityType>) => {
        setTotalRows(prevTotalRows =>
          response?.count !== undefined ? response?.count : prevTotalRows
        );
        setChannelActivityData(response.results);
      })
      .catch(error => {
        feedbackError(t('channel_activity.channel_activity_error'));
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'channel_ifdescr',
      headerName: t('channel_activity.channel_name'),
      flex: 1,
      renderCell: ({ row }) => {
        if (!row?.channel_ifdescr) {
          return '---';
        }
        const cmtsId = row.cmts;
        const macDomainIfIndex = row.mac_domain_ifindex;
        const channelIfIndex = row.channel_ifindex;
        const channelDirection = row.channel_direction;

        return channelDirection === 'downstream' ? (
          <Link
            to={NavRoutes.deployment.ds_channel.param(cmtsId, macDomainIfIndex, channelIfIndex)}
          >
            {row.channel_ifdescr}
          </Link>
        ) : (
          <Link
            to={NavRoutes.deployment.us_channel.param(cmtsId, macDomainIfIndex, channelIfIndex)}
          >
            {row.channel_ifdescr}
          </Link>
        );
      }
    },
    {
      field: 'channel_direction',
      headerName: t('channel_activity.direction'),
      flex: 1
    },
    {
      field: 'trace.channel_status',
      headerName: t('channel_activity.deployment_status'),
      flex: 1,
      valueGetter: (value, row: ChannelActivityType) => {
        if (!row?.trace || !row?.trace?.states || !row.trace?.states?.length) {
          return t('channel_activity.inactive');
        }
        const latest = row.trace.states[row.trace.states.length - 1].state;
        return latest;
      }
    },
    {
      field: 'trace.timestamp_end',
      headerName: t('channel_activity.deploy_end'),
      flex: 1,
      valueGetter: (value, row: ChannelActivityType) => {
        if (!row?.trace?.timestamp_end) {
          return '---';
        }
        const formattedDate = DateFormatted(String(row.trace.timestamp_end));
        return formattedDate;
      }
    },
    {
      field: 'trace.last_deployed',
      headerName: t('channel_activity.last_deployed'),
      flex: 1,
      valueGetter: (value, row: ChannelActivityType) => {
        if (!row?.trace?.timestamp_end) {
          return '---';
        }
        const timestamp_end = new Date(String(row.trace.timestamp_end));
        const currentTime = new Date();

        const differenceInMilliseconds = currentTime.getTime() - timestamp_end.getTime();

        const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);

        const differenceInHours = Math.floor(differenceInMilliseconds / 3600000);

        const differenceInDays = Math.floor(differenceInMilliseconds / 86400000);

        return t('channel_activity.last_deployed_time', {days: differenceInDays, hours: (differenceInHours%24), minutes: (differenceInMinutes%60)});
      }
    },
    {
      field: 'automatic',
      headerName: t('channel_activity.setting'),
      flex: 1,
      valueGetter: (value: boolean) => {
        if (value === true) {
          return t('channel_activity.automatic');
        } else if (value == false) {
          return t('channel_activity.manual');
        } else {
          return t('channel_activity.pending');
        }
      }
    },
    {
      field: 'trace.initiator',
      headerName: t('channel_activity.deployed_by'),
      flex: 1,
      valueGetter: (value, row: ChannelActivityType) => {
        if (!row?.trace?.initiator) {
          return '---';
        }
        return row.trace.initiator;
      }
    },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      headerName: t('channel_activity.rxmer_data'),
      width: 100,
      sortable: false,
      renderCell: params => <CustomDetailPanelToggle id={params.id} value={params.value} />
    }
  ];

  const handlePaginationChange = (model: GridPaginationModel) => {
    const page = model.page;
    const limit = model.pageSize;

    setOffset(page * limit);
    setLimit(limit);
  };

  const handleSelectionChange = (model: GridRowSelectionModel) => {
    const selectedIDs = new Set(model);
    const selectedRows = channelActivityData.filter(row => selectedIDs.has((row.channel_id + row.channel_direction)));
    setMultipleSelectedChannels(selectedRows);
    setRowSelectionModel(selectedRows.map(row => (row.channel_id + row.channel_direction)));
  };

  const oneRowSelected = multipleSelectedChannels[0];

  return (
    <Grid xs={12}>
      <DataTable
        rows={channelActivityData}
        columns={columns}
        onPaginationChange={handlePaginationChange}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={rowSelectionModel}
        totalRows={totalRows}
        checkboxSelection={true}
        gridDetailPanelContent={true}
        dataTableType="channelActivity"
        showExport={true}
        showSearch={true}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '5px 0',
            backgroundColor: theme => theme.palette.primary.main,
            borderRadius: '8px 8px 0 0'
            // borderBottom: (theme) => `1px solid ${theme.palette.grey}`,
          }}
        >
          <Box sx={{ flexGrow: '1', display: 'flex' }}>
            <Box
              sx={{
                paddingLeft: '10px',
                // paddingTop: "10px",
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <MenuIcon fontSize="small" sx={{ color: '#FEFEFE' }} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: '10px',
                paddingTop: '5px',
                color: '#FEFEFE',
                marginRight: 'auto'
              }}
            >
              {t('channel_activity.title')}
            </Typography>
          </Box>
        </Box>
      </DataTable>
      <ChannelActivityButtonBar>
        <ChannelActivityScheduleAutomatedProfile
          selectedChannels={multipleSelectedChannels}
          disabled={rowSelectionModel.length >= 1 ? false : true}
        />
        <ChannelActivityDisableAutomatedProfile
          selectedChannels={multipleSelectedChannels}
          disabled={rowSelectionModel.length >= 1 ? false : true}
        />
        <ChannelActivityDeployCalculatedProfiles
          selectedChannels={multipleSelectedChannels}
          disabled={rowSelectionModel.length >= 1 ? false : true}
        />
        <ChannelActivityRollBackProfiles
          selectedChannels={multipleSelectedChannels}
          disabled={rowSelectionModel.length >= 1 ? false : true}
        />
      </ChannelActivityButtonBar>
      {rowSelectionModel && rowSelectionModel.length === 1 && (
        <ChannelActivityManualDeployment selectedChannel={oneRowSelected} />
      )}
    </Grid>
  );
}

export function ChannelActivity({
  cmtsId,
  macDomainIfIndex,
  channelIfIndex
}: ChannelActivityContentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <ChannelActivityContent
        cmtsId={cmtsId}
        macDomainIfIndex={macDomainIfIndex}
        channelIfIndex={channelIfIndex}
      />
    </FeedbackContextProvider>
  );
}
