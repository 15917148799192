import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CmtsTag } from '../../Api/Types/Config';
import { ListApiResponse } from '../../Api/Types/General';
import { ApiRoutes } from '../../Api/ApiRoutes';
import { FeedbackContextProvider } from '../../Feedback/FeedbackContext';
import { useFeedbackContext } from '../../Feedback/FeedbackContext';
import { ApiGet } from '../../Api/Util';
import { useTranslation } from 'react-i18next';

type IdMap<T> = { [key: string]: T };

export type RegionSelectorComponentProp = {
  width?: number;
  direction?: string;
  currentCmtsTagIds: string[];
  setCurrentCmtsTagIds: Dispatch<SetStateAction<string[]>>;
  setCmtsTagIdToCmtsTag: Dispatch<SetStateAction<IdMap<CmtsTag>>>;
  cmtsTagIdToCmtsTag: IdMap<CmtsTag>;
};

export function RegionSelectorComponentContent({
  width,
  direction,
  currentCmtsTagIds,
  setCurrentCmtsTagIds,
  cmtsTagIdToCmtsTag,
  setCmtsTagIdToCmtsTag
}: RegionSelectorComponentProp): JSX.Element {
  const { feedbackError } = useFeedbackContext();
  const { t } = useTranslation();

  const [selectedRegions, setSelectedRegions] = useState<string[]>(currentCmtsTagIds);

  const handleCmtsTagClose = (): void => {
    setCurrentCmtsTagIds(selectedRegions);
  };

  const handleCmtsTagChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value }
    } = event;
    const values = typeof value === 'string' ? value.split(',') : value;

    if (selectedRegions.includes('all') && values.length > 1 && values.includes('all')) {
      const newTag = values.filter(el => {
        return el !== 'all';
      });
      setSelectedRegions(newTag);
    } else {
      if (values.includes('all')) {
        setSelectedRegions(['all']);
      } else {
        if (values.length === 0) {
          setSelectedRegions(['all']);
        } else {
          setSelectedRegions(values);
        }
      }
    }
  };

  const naturalSort = (array: CmtsTag[]): CmtsTag[] => {
    array = array.sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
    return array;
  };

  useEffect(() => {
    ApiGet<ListApiResponse<CmtsTag>>(ApiRoutes.config.cmtsTags.all)
      .then(async (response: any) => {
        const cmtsTagIdToCmtsTag: { [key: string]: CmtsTag } = {};
        const sortedResult = naturalSort(response.results);
        sortedResult.map(result => {
          if (result.id === undefined) {
            return;
          }
          cmtsTagIdToCmtsTag[Number(result.id)] = result;
        });

        setCmtsTagIdToCmtsTag(cmtsTagIdToCmtsTag);
      })
      .catch(error => {
        feedbackError(t('selectors.region_error'));
      });
  }, []);

  const cmtsTagList: CmtsTag[] = Object.values(cmtsTagIdToCmtsTag);

  const defaultKey: string[] = cmtsTagList.length
    ? [Number(cmtsTagList[0].id).toString()]
    : ['all'];

  return (
    <Grid lg={width ? width : 3} sx={{ padding: 0 }}>
      <FormControl
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Typography
          sx={{
            fontFamily: 'Encode Sans',
            alignSelf: 'center',
            padding: '8px 7px',
            borderRadius: '6px 0px 0px 6px',
            color: '#fff',
            fontWeight: '600',
            backgroundColor:
              direction === 'upstream'
                ? theme => theme.colors.direction.upstream
                : direction === 'downstream'
                  ? theme => theme.colors.direction.downstream
                  : theme => theme.palette.primary.main
          }}
        >
          {t('selectors.regions')}
        </Typography>
        <Select
          // This solves an out-of-range warning
          value={cmtsTagList.length ? selectedRegions : ['all']}
          onChange={handleCmtsTagChange}
          onClose={handleCmtsTagClose}
          displayEmpty
          label={t('selectors.regions_aria')}
          data-testid="regionsSelector"
          multiple
          defaultValue={defaultKey}
          inputProps={{
            'data-testid': 'selected',
            'aria-label': t('selectors.regions_aria'),
            // "aria-selected": !!cmtsTagName
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#FEFEFE'
                }
              }
            }
          }}
          sx={{
            borderRadius: '0px 6px 6px 0',
            width: '100%',
            flex: 1,
            '& .MuiSvgIcon-root': {
              color: theme => theme.palette.secondary.main
            },
            '& .MuiOutlinedInput-notchedOutline span': {
              display: 'none'
            },
            '& .MuiSelect-select': {
              backgroundColor: '#FEFEFE'
            }
          }}
        >
          <MenuItem value="all">{t('selectors.all')}</MenuItem>
          {cmtsTagList.length &&
            cmtsTagList.map(region => {
              return (
                <MenuItem key={Number(region.id)} value={Number(region.id).toString()}>
                  {region.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Grid>
  );
}

export function RegionSelectorComponent({
  width,
  direction,
  currentCmtsTagIds,
  setCurrentCmtsTagIds,
  cmtsTagIdToCmtsTag,
  setCmtsTagIdToCmtsTag
}: RegionSelectorComponentProp): JSX.Element {
  return (
    <FeedbackContextProvider>
      <RegionSelectorComponentContent
        width={width}
        direction={direction}
        currentCmtsTagIds={currentCmtsTagIds}
        setCurrentCmtsTagIds={setCurrentCmtsTagIds}
        cmtsTagIdToCmtsTag={cmtsTagIdToCmtsTag}
        setCmtsTagIdToCmtsTag={setCmtsTagIdToCmtsTag}
      />
    </FeedbackContextProvider>
  );
}
