import { Card, CardContent, Typography, CardHeader } from '@mui/material';
import React from 'react';

export type GrafanaPanelProps = {
  name: string;
  description: string;
  isDownstream: boolean | null;
};

export const FakeGrafanaPanel = ({ name, description, isDownstream }: GrafanaPanelProps) => {
  return (
    <Card sx={{ borderRadius: '8px', height: '100%' }}>
      <CardHeader
        title={name}
        sx={{
          textAlign: 'center',
          backgroundColor: theme =>
            isDownstream !== null
              ? isDownstream
                ? theme.colors.direction.downstream
                : theme.colors.direction.upstream
              : theme.colors.direction.downstream,
          '& .MuiCardHeader-title': {
            fontSize: '1.25rem',
            lineHeight: '1',
            color: '#FEFEFE'
          }
        }}
      />
      <CardContent>
        <Typography variant="h5" sx={{ color: 'text.secondary', textAlign: 'center' }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};
