import * as React from 'react';
import {
  DataGridPro,
  DataGridProProps,
  GridPaginationModel,
  GridRowEditStopParams,
  GridRowEditStopReasons,
  GridRowModesModel,
  GridRowSelectionModel,
  MuiEvent
} from '@mui/x-data-grid-pro';
import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import {
  GridColDef,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarContainer
} from '@mui/x-data-grid-pro';
import { darken, lighten, styled } from '@mui/material/styles';
import { ChannelInformationExpanded } from '../Deployment/ChannelInformationExpanded';

export type DataTableProp = {
  rows: any[];
  columns: GridColDef[];
  children?: JSX.Element[] | JSX.Element;
  onPaginationChange?: (model: GridPaginationModel) => void;
  onRowSelectionModelChange?: (model: GridRowSelectionModel) => void;
  rowSelectionModel?: GridRowSelectionModel;
  totalRows?: number;
  checkboxSelection?: boolean;
  dataTableType?: string;
  onRowModesModelChange?: (model: GridRowModesModel) => void;
  rowModesModel?: GridRowModesModel;
  setRowModesModel?: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  processRowUpdate?: (newRow: any, originalRow: any) => void;
  processRowUpdateError?: (model: any) => void;
  gridDetailPanelContent?: boolean;
  pageSize?: number;
  showExport?: boolean;
  showSearch?: boolean;
};

function CustomToolbar({
  showExport,
  showSearch
}: {
  showExport: boolean | undefined;
  showSearch: boolean | undefined;
}) {
  return (
    <GridToolbarContainer
      sx={{
        pt: 1.5,
        pb: 0
      }}
    >
      {showSearch && <GridToolbarQuickFilter sx={{ flex: 1 }} />}
      {showExport && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const getColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0) : lighten(color, 0);

const getHoverColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.3) : lighten(color, 0.3);

const getSelectedColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.2) : lighten(color, 0.2);

const getSelectedHoverColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.1) : lighten(color, 0.1);

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .datatable-theme--failed': {
    color: getColor(theme.colors.audit.failed, theme.palette.mode),
    '&:hover': {
      color: getHoverColor(theme.colors.audit.failed, theme.palette.mode)
    },
    '&.Mui-selected': {
      color: getSelectedColor(theme.colors.audit.failed, theme.palette.mode),
      '&:hover': {
        color: getSelectedHoverColor(theme.colors.audit.failed, theme.palette.mode)
      }
    }
  }
})) as typeof DataGridPro;

export default function DataTable({
  rows,
  columns,
  children,
  onPaginationChange,
  onRowSelectionModelChange,
  rowSelectionModel,
  totalRows,
  checkboxSelection,
  dataTableType,
  onRowModesModelChange,
  rowModesModel,
  setRowModesModel,
  processRowUpdate,
  processRowUpdateError,
  gridDetailPanelContent,
  pageSize,
  showExport,
  showSearch
}: DataTableProp) {
  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <ChannelInformationExpanded row={row} />, []);

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            boxShadow: 'none',
            borderRadius: '0 0 4px 4px',
            padding: '0'
          }}
        >
          {children}
          <div style={{ width: '100%' }}>
            <StyledDataGridPro
              autoHeight
              pagination
              rows={rows}
              rowCount={totalRows}
              columns={columns}
              getRowId={(row: any) =>
                dataTableType === 'audit'
                  ? Number(row.trace_id)
                  : dataTableType === 'cmtsDetails'
                    ? Number(row.cmts)
                    : dataTableType === 'channelActivity'
                      ? row.channel_id + row.channel_direction
                      : dataTableType === 'channelsRxmer'
                        ? row.ds_channel_id
                        : row?.id
              }
              getRowClassName={params => {
                if (params.row?.states?.length === undefined) return `datatable-theme--none`;
                return `datatable-theme--${
                  params.row?.states[params.row?.states?.length - 1]?.state
                }`;
              }}
              editMode={dataTableType === 'audit' ? undefined : 'row'}
              onRowEditStop={(params: GridRowEditStopParams, event: MuiEvent) => {
                if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                  event.defaultMuiPrevented = true;
                }
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableColumnFilter
              checkboxSelection={checkboxSelection}
              disableRowSelectionOnClick={dataTableType === 'audit' ? undefined : true}
              disableColumnSelector
              disableDensitySelector
              paginationMode="server"
              onPaginationModelChange={newModel =>
                onPaginationChange && onPaginationChange(newModel)
              }
              onRowSelectionModelChange={newModel =>
                onRowSelectionModelChange && onRowSelectionModelChange(newModel)
              }
              rowSelectionModel={rowSelectionModel && rowSelectionModel}
              onRowModesModelChange={newModel =>
                onRowModesModelChange && onRowModesModelChange(newModel)
              }
              rowModesModel={rowModesModel && rowModesModel}
              processRowUpdate={
                processRowUpdate &&
                ((newRow: any, originalRow: any) => processRowUpdate(newRow, originalRow))
              }
              onProcessRowUpdateError={processRowUpdateError && processRowUpdateError}
              getDetailPanelContent={gridDetailPanelContent ? getDetailPanelContent : undefined}
              getDetailPanelHeight={gridDetailPanelContent ? getDetailPanelHeight : undefined}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'timestamp_start', sort: 'desc' }]
                },
                pagination: { paginationModel: { pageSize: pageSize ? pageSize : 100 } }
              }}
              slots={{
                toolbar: props => (
                  <CustomToolbar showExport={showExport} showSearch={showSearch} {...props} />
                )
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  setRowModesModel: setRowModesModel && setRowModesModel
                }
              }}
              //remove the selection outline
              sx={{
                borderLeft: '.5px solid #44556C',
                borderRight: '.5px solid #44556C',
                borderBottom: '.5px solid #44556C',
                borderRadius: '0 0 8px 8px',
                backgroundColor: '#FEFEFE',
                fontFamily: 'Encode Sans',
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important'
                },
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible'
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important'
                }
              }}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
