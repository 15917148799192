import { Icon } from "@mui/material";
import React from "react";

export const GrafanaIcon = (
  <Icon>
    <img
      alt="grafana"
      src="/grafanalogo.svg"
      style={{ height: "100%", width: "100%", scale: "1.8" }}
    />
  </Icon>
);

export const OVLogo = (
  <Icon>
    <img
      alt="Openvault Logo"
      src="/OVLogo.png"
      style={{ height: "100%", width: "100%" }}
    />
  </Icon>
)

export function DashNavIcon(): JSX.Element {
  return <Icon>
    <img
      alt="Dashboard"
      src="/icons/pma_dash.svg"
      style={{ height: "100%", width: "100%" }}
    />
  </Icon>
}

export function ChannelViewNavIcon(): JSX.Element {
  return <Icon>
    <img
      alt="Channel View"
      src="/icons/pma_channel.svg"
      style={{ height: "100%", width: "100%" }}
    />
  </Icon>
}

export function RxmerViewNavIcon(): JSX.Element {
  return <Icon>
    <img
      alt="Rxmer View"
      src="/icons/pma_rxmer.svg"
      style={{ height: "100%", width: "100%" }}
    />
  </Icon>
}

export function ProfileManagementNavIcon(): JSX.Element {
  return <Icon>
    <img
      alt="Profile Management"
      src="/icons/pma_p.mgmt.svg"
      style={{ height: "100%", width: "100%" }}
    />
  </Icon>
}

export function SystemConfigurationNavIcon(): JSX.Element {
  return <Icon>
    <img
      alt="Config"
      src="/icons/pma_config.svg"
      style={{ height: "100%", width: "100%" }}
    />
  </Icon>
}

export function AdminNavIcon(): JSX.Element {
  return <Icon>
    <img
      alt="Admin"
      src="/icons/pma_admin.svg"
      style={{ height: "100%", width: "100%" }}
    />
  </Icon>
}
