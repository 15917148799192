import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GrafanaPanel } from '../../Components/GrafanaPanel';
import { useTranslation } from 'react-i18next';
import { useChannelDeploymentContext } from './DeploymentContext';
import { PmaProfile, PmaResult } from '../../Api/Types/CapacityBooster';
import { StyledDivider } from '../../OperationalCM/StyledDivider';
import { Cmts } from '../../Api/Types/Config';

type ProfileGraphProps = {
  profileId?: number;
  dataIuc?: number;
  calculated: boolean;
  cmtsInfo?: Cmts | null;
  macDomainIfDescr?: string;
  channelIfDescr?: string;
};

export function DownstreamProfileGraph(props: ProfileGraphProps): JSX.Element {
  const { profileId, calculated, cmtsInfo, macDomainIfDescr, channelIfDescr } = props;

  const dashboardUrl = '/grafana/d-solo/zCgyNO0Vk/operational-deploy-downstream-channel';
  const { t } = useTranslation();
  const existingProfilePanelId = 2;
  const calculatedProfilePanelId = 5;

  const { cmts, dsChannel, macDomain } = useChannelDeploymentContext();
  const cmtsName = cmts?.name || cmtsInfo?.name;
  const macDomainIfDescrValue = macDomain?.ifDescr || macDomainIfDescr;
  const channelIfDescrValue = dsChannel?.ifDescr || channelIfDescr;

  const extraParams = {
    'var-cmts': cmtsName,
    'var-mac_dom': macDomainIfDescrValue,
    'var-ds_chan': channelIfDescrValue,
    'var-cur_profile_id': profileId,
    'var-boosted_profile_id': profileId,
    'from': 'now-24h',
    'to': 'now'
  };

  return (cmts || cmtsInfo) ? (
    <GrafanaPanel
      url={dashboardUrl}
      panelId={calculated ? calculatedProfilePanelId : existingProfilePanelId}
      extraParams={extraParams}
      width="100%"
      height="300px"
    />
  ) : (
    <Typography width="100%" height="300px">
      {t('deploy.loading')}
    </Typography>
  );
}

export function UpstreamProfileGraph(props: ProfileGraphProps): JSX.Element {
  const { dataIuc, calculated, cmtsInfo, macDomainIfDescr, channelIfDescr } = props;

  const dashboardUrl = '/grafana/d-solo/bZdfpsuVz/operational-deploy-upstream-channel';
  const { t } = useTranslation();
  const existingProfilePanelId = 2;
  const calculatedProfilePanelId = 5;

  const { cmts, usChannel, macDomain } = useChannelDeploymentContext();
  const cmtsName = cmts?.name || cmtsInfo?.name;
  const macDomainIfDescrValue = macDomain?.ifDescr || macDomainIfDescr;
  const channelIfDescrValue = usChannel?.ifDescr || channelIfDescr;

  const extraParams = {
    'var-cmts': cmtsName,
    'var-mac_dom': macDomainIfDescrValue,
    'var-us_chan': channelIfDescrValue,
    'var-cur_profile_id': dataIuc,
    'var-boosted_profile_id': dataIuc,
    'from': 'now-24h',
    'to': 'now'
  };

  return (cmts || cmtsInfo) ? (
    <GrafanaPanel
      url={dashboardUrl}
      panelId={calculated ? calculatedProfilePanelId : existingProfilePanelId}
      extraParams={extraParams}
      width="100%"
      height="300px"
    />
  ) : (
    <Typography width="100%" height="300px">
      {t('deploy.loading')}
    </Typography>
  );
}

type ProfileSetDisplayProps = {
  pmaResult: PmaResult;
  downstream: boolean;
  cmtsInfo?: Cmts | null;
  macDomainIfDescr?: string;
  channelIfDescr?: string;
};

function DeployedProfileColumn({
  pmaResult,
  downstream,
  cmtsInfo,
  macDomainIfDescr,
  channelIfDescr
}: ProfileSetDisplayProps): JSX.Element {
  const { t } = useTranslation();
  const deployedProfilesRendered = pmaResult.deployed_profiles.map(
    (profile: PmaProfile, index: number) => {
      return (
        <Grid2 key={index} xs={12} md={12} lg={12}>
          {downstream ? (
            <DownstreamProfileGraph
              profileId={profile.profile_id}
              calculated={false}
              cmtsInfo={cmtsInfo}
              macDomainIfDescr={macDomainIfDescr}
              channelIfDescr={channelIfDescr}
            />
          ) : (
            <UpstreamProfileGraph
              dataIuc={profile.data_iuc}
              calculated={false}
              cmtsInfo={cmtsInfo}
              macDomainIfDescr={macDomainIfDescr}
              channelIfDescr={channelIfDescr}
            />
          )}
        </Grid2>
      );
    }
  );

  return (
    <>
      <Grid2 md={12} sx={{ marginBottom: '20px' }}>
        <StyledDivider textAlign="left">
          <Typography fontWeight={'600'}>{t('deploy.deployed_profiles')}</Typography>
        </StyledDivider>
      </Grid2>
      <Grid2
        md={12}
        sx={{
          //   border: "1px solid #C6C6C6",
          borderRadiusTopLeft: '10px',
          borderRadiusBottomLeft: '10px'
        }}
      >
        {deployedProfilesRendered.map(item => item)}
      </Grid2>
    </>
  );
}

function DefaultProfileColumn({ pmaResult, downstream }: ProfileSetDisplayProps): JSX.Element {
  const { t } = useTranslation();
  const deployedProfilesRendered = pmaResult.default_profiles.map(
    (profile: PmaProfile, index: number) => {
      return (
        <Grid2 key={index} xs={12} md={12} lg={12}>
          {downstream ? (
            <DownstreamProfileGraph profileId={profile.profile_id} calculated={false} />
          ) : (
            <UpstreamProfileGraph dataIuc={profile.data_iuc} calculated={false} />
          )}
        </Grid2>
      );
    }
  );

  return (
    <>
      <Grid2 md={12} sx={{ marginBottom: '20px' }}>
        <StyledDivider
          textAlign="left"
          sx={{
            color: '#33751F',
            '&::before, &::after': {
              borderColor: '#33751F'
            }
          }}
        >
          <Typography textAlign="right">{t('deploy.default_profiles')}</Typography>
        </StyledDivider>
      </Grid2>
      <Grid2
        md={12}
        sx={{
          //   border: "1px solid #C6C6C6",
          borderRadiusTopRight: '10px',
          borderRadiusBottomRight: '10px'
        }}
      >
        {deployedProfilesRendered.map(item => item)}
      </Grid2>
    </>
  );
}

function CalculatedProfileColumn({
  pmaResult,
  downstream,
  cmtsInfo,
  macDomainIfDescr,
  channelIfDescr
}: ProfileSetDisplayProps): JSX.Element {
  const { t } = useTranslation();
  const calculatedProfilesRendered = pmaResult.calculated_profiles.map(
    (profile: PmaProfile, index: number) => {
      return (
        <Grid2 key={index} xs={12} md={12} lg={12}>
          {downstream ? (
            <DownstreamProfileGraph
              profileId={profile.profile_id}
              calculated={true}
              cmtsInfo={cmtsInfo}
              macDomainIfDescr={macDomainIfDescr}
              channelIfDescr={channelIfDescr}
            />
          ) : (
            <UpstreamProfileGraph
              dataIuc={profile.data_iuc}
              calculated={true}
              cmtsInfo={cmtsInfo}
              macDomainIfDescr={macDomainIfDescr}
              channelIfDescr={channelIfDescr}
            />
          )}
        </Grid2>
      );
    }
  );

  return (
    <>
      <Grid2 md={12} sx={{ marginBottom: '20px' }}>
        <StyledDivider
          textAlign="left"
          sx={{
            color: '#33751F',
            '&::before, &::after': {
              borderColor: '#33751F'
            }
          }}
        >
          <Typography fontWeight={'600'}>{t('deploy.calculated_profiles')}</Typography>
        </StyledDivider>
      </Grid2>
      <Grid2
        md={12}
        sx={{
          // border: "1px solid #C6C6C6",
          borderRadiusTopRight: '10px',
          borderRadiusBottomRight: '10px'
        }}
      >
        {calculatedProfilesRendered.map(item => item)}
      </Grid2>
    </>
  );
}

export function ProfileSetDisplay(props: ProfileSetDisplayProps): JSX.Element {
  return (
    <Grid2 md={12} spacing={2} container>
      <Grid2 md={6} p={0}>
        <DeployedProfileColumn {...props} />
      </Grid2>
      <Grid2 md={6} p={0}>
        <CalculatedProfileColumn {...props} />
        {/*<DefaultProfileColumn {...props}/>*/}
      </Grid2>
    </Grid2>
  );
}
