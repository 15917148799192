import {
  GraphPanelStyleDownstream,
  GraphPanelStyleUpstream,
  LazyGrafanaPanel,
  RankingPanelStyleDownstream,
  RankingPanelStyleUpstream,
  SingleValuePanelStyleDownstream,
  SingleValuePanelStyleUpstream
} from '../Components/GrafanaPanel';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect, useRef} from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { StyledDivider } from '../OperationalCM/StyledDivider';
import { useGrafanaLink } from '../Dashboard/Dashboard';
import { ViewActionButton } from '../Components/ViewActionButton';
import { Cmts } from '../Api/Types/Config';
import {DsChannel, MacDomain, UsChannel} from '../Api/Types/Topology';
import { CmtsSelectorComponent } from '../Components/Selectors/CmtsSelectorComponent';
import { MacDomainSelectorComponent } from '../Components/Selectors/MacDomainSelectorComponent';
import {ChannelSelectorComponent} from "../Components/Selectors/ChannelSelectorComponent";
import { ChannelDirection } from '../Api/Util';

const DownstreamPanelIDs = {
  streamDirection: ChannelDirection.Downstream,
  ChannelCapacityGained: 14,
  BoostedCapacity: 8,
  BaselineCapacity: 13,
  ChannelParameters: 18,
  ChannelCapacityGainedChart: 10,
  BoostedVBaselineCapacity: 9,
  BoostedCMDistribution: 16,
  BoostedCMPreferredProfile: 17,
  CMs: 2,
  ChannelGained: 4,
  PmaCalculations: 6,
  ChannelSummaryFec: 20,
  // Rxmer view
  ChannelInfo: 103,
  ThreePointOneCMs: 100,
  EffectiveChannelSpeed: 113,
  LatestImpariedCMsDetail: 117,
  ChannelFecBy: 19,
  ChannelCmStatus: 119,
  ChannelUtilization: 76,
  CodewordBitloadingWeightedByProfile: 121,
  TotalCodewordsByProfile: 123,
  AssignedCMsByProfile: 33,
  TotalCWs: 125,
  ChannelCMsRegistrationStatus: 78,
  ChannelCMsPartialServiceState: 86,
  ChannelCMsPartialChannelState: 80,
  DsChannelBytesPerProfile: 40,
  CMsNotOnline: 79,
  CMsInPartialService: 87,
  CMsInPartialChannel: 88,
  CMsWorstRxmer: 101,
};

const UpstreamPanelIDs = {
  streamDirection: ChannelDirection.Upstream,
  ChannelCapacityGained: 10,
  BoostedCapacity: 8,
  BaselineCapacity: 12,
  ChannelParameters: 24,
  ChannelCapacityGainedChart: 14,
  BoostedVBaselineCapacity: 16,
  BoostedCMDistribution: 20,
  BoostedCMPreferredProfile: 22,
  CMs: 2,
  ExistingProfileUsage: 26,
  ExistingCapacity: 28,
  ChannelGained: 4,
  PmaCalculations: 6,
  // ChannelSummaryFec: 20,
  ChannelInfo: 208,
  ThreePointOneCMs: 210,
  EffectiveChannelSpeed: 105,
  LatestImpariedCMsDetail: 109,
  ChannelFecBy: 225,
  ChannelCmStatus: 161,
  ChannelUtilization: 54,
  CodewordBitloadingWeightedByIuc: 135,
  TotalCodewordsByIuc: 148,
  AssignedCMsByProfile: 221,
  TotalCWs: 219
};

type GrafanaStreamProp = {
  stream: any;
  extraParams: any;
  loading: boolean;
};

function GrafanaStream({ stream, extraParams, loading }: GrafanaStreamProp): JSX.Element {
  const isDownstream = stream.streamDirection === ChannelDirection.Downstream;
  const dashboardUrl =
    isDownstream
      ? '/grafana/d-solo/UEojLdw7k/operational-downstream-channel'
      : '/grafana/d-solo/WUXojLdw3m/operational-upstream-channel';
  const channelRxmerUrl =
    isDownstream
      ? '/grafana/d-solo/pkpIPDl7k/operational-downstream-channel-rxmer'
      : '/grafana/d-solo/Os6m0A_nk/operational-upstream-channel-rxmer';
  const techSupervisorUrl = '/grafana/d-solo/9ojKsPx4z/tech-supervisor-downstream-channel';
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));

  const SingleValuePanel =
    isDownstream
      ? SingleValuePanelStyleDownstream
      : SingleValuePanelStyleUpstream;
  const GraphPanel =
    isDownstream
      ? GraphPanelStyleDownstream
      : GraphPanelStyleUpstream;
  const RankingPanel =
    isDownstream
      ? RankingPanelStyleDownstream
      : RankingPanelStyleUpstream;

  return (
    <>
      <Grid xs={12} display="flex" gap={1} flexDirection={matchSM ? 'column' : 'row'}>
        <Grid xs={12} lg={4} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.ChannelCapacityGained}
            width="100%"
            height="100%"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={4} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.BoostedCapacity}
            width="100%"
            height="100%"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={4} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.BaselineCapacity}
            width="100%"
            height="100%"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.ChannelInfo}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.ChannelParameters}
            width="100%"
            height="300px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        {isDownstream &&
          <Grid xs={12} lg={6}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.EffectiveChannelSpeed}
              width="100%"
              height="300px"
              injectedClassName="ranking-panel"
              injectedStyle={RankingPanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
        }
        <Grid xs={12} lg={isDownstream ? 6 : 12}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.ChannelUtilization}
            width="100%"
            height="300px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.ChannelCapacityGainedChart}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.BoostedVBaselineCapacity}
            width="100%"
            height="300px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      {isDownstream && (
        <Grid container padding={2}>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.ChannelCMsRegistrationStatus}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.ChannelCMsPartialServiceState}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.ChannelCMsPartialChannelState}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.DsChannelBytesPerProfile}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
      {isDownstream && (
        <Grid container padding={2}>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.CMsNotOnline}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.CMsInPartialService}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.CMsInPartialChannel}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} lg={3} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={techSupervisorUrl}
              panelId={stream.CMsWorstRxmer}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.BoostedCMDistribution}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.BoostedCMPreferredProfile}
            width="100%"
            height="300px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" padding="0">
        <Grid xs={12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.CMs}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" padding="0">
        <Grid xs={12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.ChannelGained}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" padding="0">
        <Grid xs={12}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.PmaCalculations}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" padding="0">
        <Grid xs={12}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.ChannelFecBy}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}

type IdMap<T> = { [key: string]: T };

export function ChannelView(): JSX.Element {
  const { t } = useTranslation();

  const { setGrafanaLink, setGrafanaLinkButton } = useGrafanaLink();

  const DsDashboardLinkUrl = '/grafana/d/UEojLdw7k/operational-downstream-channel';
  const UsDashboardLinkUrl = '/grafana/d/WUXojLdw3m/operational-upstream-channel';

  const [cmtsIdToCmts, setCmtsIdToCmts] = useState<IdMap<Cmts>>({});
  const [macDomainIdToMacDomain, setMacDomainIdToMacDomain] = useState<IdMap<MacDomain>>({});
  const [channelIdToChannel, setChannelIdToChannel] = useState<
    IdMap<DsChannel | UsChannel>
  >({});

  const [searchParams, setSearchParams] = useSearchParams({
    cmts: '',
    macdomain: '',
    channel: ''
  });

  useEffect(() => {
    setGrafanaLinkButton(true);
  }, []);

  const cmtsParam: string | null = searchParams.get('cmts');
  const [currentCmtsId, setCurrentCmtsId] = useState(cmtsParam ? cmtsParam : '');
  const macDomainParam = searchParams.get('macdomain');
  const [currentMacDomainId, setCurrentMacDomainId] = useState(
    macDomainParam ? macDomainParam : ''
  );

  const channelParam: string | null = searchParams.get("channel");
  const [currentChannelId, setCurrentChannelId] = useState(
    channelParam ? channelParam : ""
  );

  const cmstName = Object.hasOwn(cmtsIdToCmts, currentCmtsId)
    ? cmtsIdToCmts[currentCmtsId].name
    : '';
  const macDomainIfDescr = Object.hasOwn(macDomainIdToMacDomain, currentMacDomainId)
    ? macDomainIdToMacDomain[currentMacDomainId].ifDescr
    : '';
  const channelIfDescr = Object.hasOwn(channelIdToChannel, currentChannelId)
    ? channelIdToChannel[currentChannelId].ifDescr
    : '';

  const macDomainIfIndex = Object.hasOwn(
    macDomainIdToMacDomain,
    currentMacDomainId
  )
    ? macDomainIdToMacDomain[currentMacDomainId].ifIndex.toString()
    : "";
  const channelIfIndex = Object.hasOwn(
    channelIdToChannel,
    currentChannelId
  )
    ? channelIdToChannel[currentChannelId].ifIndex.toString()
    : "";

  const currentChannelDirection = Object.hasOwn(
    channelIdToChannel,
    currentChannelId
  ) ? channelIdToChannel[currentChannelId].ifDescr.toLowerCase().includes('downstream') ? ChannelDirection.Downstream : ChannelDirection.Upstream : "";

  useEffect(() => {
    setSearchParams({
      cmts: currentCmtsId,
      macdomain: currentMacDomainId,
      channel: currentChannelId
    });
    if(currentChannelDirection === ChannelDirection.Downstream){
      setGrafanaLink(
        `${DsDashboardLinkUrl}?var-cmts=${cmstName}&var-mac_dom=${macDomainIfDescr}&var-ds_chan=${channelIfDescr}`
      );
    }else{
      setGrafanaLink(
        `${UsDashboardLinkUrl}?var-cmts=${cmstName}&var-mac_dom=${macDomainIfDescr}&var-us_chan=${channelIfDescr}`
      );
    }
  }, [currentCmtsId, currentMacDomainId, currentChannelId, currentChannelDirection]);

  const extraParams = {
    'var-cmts': cmstName,
    'var-mac_dom': macDomainIfDescr,
    'var-ds_chan': channelIfDescr
  };

  const rxmerUrl = `/rxmer-view/?cmts=${currentCmtsId}&macdomain=${currentMacDomainId}&channel=${currentChannelId}`;

  return (
    <Grid container spacing={2} id='channel-view-content'>
      <Grid
        xs={12}
        sx={{
          display: 'flex',
          padding: 0,
          flexDirection: { sm: 'column', md: 'row' }
        }}
      >
        <CmtsSelectorComponent
          width={3}
          currentCmtsId={currentCmtsId}
          setCurrentCmtsId={setCurrentCmtsId}
          cmtsIdToCmts={cmtsIdToCmts}
          setCmtsIdToCmts={setCmtsIdToCmts}
          firstItem
        />
        <MacDomainSelectorComponent
          width={3}
          cmtsId={currentCmtsId}
          currentMacDomainId={currentMacDomainId}
          setCurrentMacDomainId={setCurrentMacDomainId}
          macDomainIdToMacDomain={macDomainIdToMacDomain}
          setMacDomainIdToMacDomain={setMacDomainIdToMacDomain}
          firstItem
        />
        <ChannelSelectorComponent
          width={3}
          cmtsId={currentCmtsId}
          macDomainIfIndex={macDomainIfIndex}
          currentChannel={currentChannelId}
          setCurrentChannel={setCurrentChannelId}
          channelIdToChannel={channelIdToChannel}
          setChannelIdToChannel={setChannelIdToChannel}
          firstItem
        />
        <ViewActionButton
          width={3}
          url={rxmerUrl}
          name={t('channel_view.rxmer_button')}
        />
      </Grid>
      {currentChannelDirection === ChannelDirection.Downstream ? (
        <Grid xs={12}>
          <GrafanaStream
            stream={DownstreamPanelIDs}
            extraParams={extraParams}
            loading={false}
          />
        </Grid>

      ) : (
        <Grid xs={12}>
          <GrafanaStream
            stream={UpstreamPanelIDs}
            extraParams={extraParams}
            loading={false}
          />
        </Grid>
      )
      }
    </Grid>
  );
}
