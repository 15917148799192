import {PmaDsProfile, PmaDsResult} from "../../../../Api/Types/CapacityBooster";
import {Cmts} from "../../../../Api/Types/Config";
import {DsChannel, MacDomain} from "../../../../Api/Types/Topology";
import {
  AddDefaultProfileIfMissing,
  ALLOWED_PROFILE_IDS,
  BITLOADING_TO_E6K_DS_MODULATION,
  DEFAULT_PROFILE_TYPE,
  INDENT
} from "./Common";
import {SimpleDeepCopy} from "../../../../Utils/SimpleDeepCopy";
import {BigIntReplacer} from "../../../../Utils/Converters";

export function ConstructE6kDsInstructions(
  t_no_escape: (key: string, options?: any) => string,
  cmts: Cmts | null,
  macDomain: MacDomain | null,
  dsChannel: DsChannel | null,
  pmaDsResult: PmaDsResult | null,
  includeSafetyChecks: boolean,
  includePmaDsResult: boolean
): string {
  const E6K_DS_CHAN_DESCR_RE
    = /cable-ds-ofdm (?<interfaceCableDsId>(?<linecardId>\d+)\/\d+\/\d+)/;
  const E6K_DS_UNUSED_PROFILE_BITLOADING = 12;

  // ====
  const mutablePmaDsResult = SimpleDeepCopy(pmaDsResult);

  const resultsLines: string[] = [];
  resultsLines.push(`# ${t_no_escape("deploy.commands.instructions_title")}`);
  if (mutablePmaDsResult == null || !mutablePmaDsResult.calculated_profiles) {
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.no_profile_data")}`);
    return resultsLines.join("\n");
  }

  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_cmts_mac_domain_ds_channel", {
    cmts: mutablePmaDsResult.cmts_name,
    mac_domain: mutablePmaDsResult.md_ifdescr,
    ds_channel: mutablePmaDsResult.ds_ifdescr
  })}`);
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.for_pma_task_id_ds_result_id", {
    pma_task_id: mutablePmaDsResult.task,
    pma_ds_result_id: mutablePmaDsResult.id
  })}`);

  let timestamp = mutablePmaDsResult.result_completed_timestamp;
  timestamp = timestamp.replace("T", " ").replace("Z", " UTC");
  resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.profiles_calculated_at_timestamp", {
    timestamp: timestamp
  })}`);

  // update profile numbering
  mutablePmaDsResult.calculated_profiles.forEach((pmaDsProfile, index) => {
    pmaDsProfile.profile_id = index;
  });

  // get cmts-specific ids
  let ds_chan_info = mutablePmaDsResult.ds_ifdescr.match(E6K_DS_CHAN_DESCR_RE)?.groups;
  if (!ds_chan_info) {
    ds_chan_info = {
      interfaceCableDsId: t_no_escape("deploy.commands.e6k.unknown_interface_cable_ds_id"),
      linecardId: t_no_escape("deploy.commands.e6k.unknown_linecard_id"),
    };
  }
  const interfaceCableDsId = ds_chan_info.interfaceCableDsId;
  const linecardId = ds_chan_info.linecardId;

  const profileIdToPmaDsProfile: { [p: number]: PmaDsProfile | null } = {};
  ALLOWED_PROFILE_IDS.forEach(profileId => {
    profileIdToPmaDsProfile[profileId] = null;
  });
  mutablePmaDsResult.calculated_profiles.forEach(pmaDsProfile => {
    profileIdToPmaDsProfile[pmaDsProfile.profile_id] = pmaDsProfile;
  });


  if (includeSafetyChecks) {

    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.safety_check_downstream_channel_intro", {
      interface_cable_ds_id: interfaceCableDsId,
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_downstream_channel_do_command")}`);
    resultsLines.push(`show running-config interface cable-downstream ${interfaceCableDsId}`
      + ` | include ofdm`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_downstream_channel_check", {
      interface_cable_ds_id: interfaceCableDsId,
    })}`);

    // check: channel frequency range matches new profiles?
    // check: subcarrier-spacing?

    // check: linecard type
    resultsLines.push("");
    resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.safety_check_linecard_intro", {
      linecard_id: linecardId,
    })}`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_linecard_do_command")}`);
    resultsLines.push(`show linecard status | include "^${linecardId} " | include DCAM2`);
    resultsLines.push(`# ${INDENT}${t_no_escape("deploy.commands.e6k.safety_check_linecard_check", {
      linecard_id: linecardId,
    })}`);
  }

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.begin_configuration_heading")}`);

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.redefine_profiles_heading")}`);
  ALLOWED_PROFILE_IDS.forEach(profileId => {
    const pmaDsProfile = profileIdToPmaDsProfile[profileId];
    if (pmaDsProfile) {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.e6k.configure_profile_id_default_bit_loading_mean_bit_loading",
        {
          profile_id: profileId,
          default_bit_loading: pmaDsProfile.default_bit_loading,
          mean_bit_loading: pmaDsProfile.mean_subcarrier_bit_loading,
          profile_type: pmaDsProfile.profile_type == DEFAULT_PROFILE_TYPE 
            ? t_no_escape("deploy.commands.e6k.configure_profile_is_default")
            : t_no_escape("deploy.commands.e6k.configure_profile_is_calculated")
        })}`);
      const modulation = BITLOADING_TO_E6K_DS_MODULATION[pmaDsProfile.default_bit_loading];
      if (pmaDsProfile.ds_subcarrier_statuses.length > 1) {
        resultsLines.push(`configure interface cable-downstream ${interfaceCableDsId} ofdm ds-profile ${profileId}`
          + ` new-modulation ${modulation} continue`);
        pmaDsProfile.ds_subcarrier_statuses.forEach(pmaDsSubcarrierStatus => {
          if (pmaDsSubcarrierStatus.main_bit_loading == pmaDsProfile.default_bit_loading) {
            return;
          }
          const modulation = BITLOADING_TO_E6K_DS_MODULATION[pmaDsSubcarrierStatus.main_bit_loading];
          resultsLines.push(
            `band ${pmaDsSubcarrierStatus.start_frequency_hz} ${pmaDsSubcarrierStatus.end_frequency_hz} ${modulation}`
          );
        });
        resultsLines.push(`/end`);
      } else {
        // simpler command for no exception zones.
        resultsLines.push(`configure interface cable-downstream ${interfaceCableDsId} ofdm ds-profile ${profileId}`
          + ` new-modulation ${modulation}`);
      }
    } else {
      resultsLines.push(`# ${INDENT}${t_no_escape(
        "deploy.commands.e6k.configure_profile_id_unused",
        {profile_id: profileId})}`);
      const modulation = BITLOADING_TO_E6K_DS_MODULATION[E6K_DS_UNUSED_PROFILE_BITLOADING];
      resultsLines.push(`configure interface cable-downstream ${interfaceCableDsId} ofdm ds-profile ${profileId}`
        + ` new-modulation ${modulation}`);
    }
  });

  resultsLines.push("");
  resultsLines.push(`# ${t_no_escape("deploy.commands.e6k.configuration_complete_heading")}`);

  // output debug info if requested
  if (includePmaDsResult) {
    resultsLines.push("");
    resultsLines.push(`# pmaDsResult: ${JSON.stringify(mutablePmaDsResult, BigIntReplacer)}`);
  }

  return resultsLines.join("\n");
}
