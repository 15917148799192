import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useMemo } from "react";
import { ListApiResponse } from "../Api/Types/General";
import { ApiRoutes } from "../Api/ApiRoutes";
import { Button, Typography } from "@mui/material";
import {
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
  GridRowModesModel,
  GridRowModel,
  GridRowModes,
  GridRowId,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import DataTable from "../Components/DataTable";
import { useTranslation } from "react-i18next";
import { useGrafanaLink } from "../Dashboard/Dashboard";
import { FeedbackContextProvider } from "../Feedback/FeedbackContext";
import { useFeedbackContext } from "../Feedback/FeedbackContext";
import { ApiGet, ApiPut, ApiDelete } from "../Api/Util";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from "@mui/material/IconButton";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { AddUser } from "./AddUser";
import { DeleteConfirmationDialog } from "../Utils/DeleteConfirmationDialog";
import {UserRoles} from "./AddUser";

export function ManageUsersContent(): JSX.Element {
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteMultipleDialog, setOpenDeleteMultipleDialog] =
    useState(false);
  const [multipleDeleteUsers, setMultipleDeleteUsers] = useState<any[]>([]);

  const { setGrafanaLinkButton } = useGrafanaLink();
  const { setFeedbackState } = useFeedbackContext();

  const [usersData, setUsersData] = useState<any[]>([]);
  const [rowToBeDeleted, setRowToBeDeleted] = useState<number>();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  useEffect(() => {
    setGrafanaLinkButton(false);
  }, []);

  const loadData = (): void => {
    ApiGet<ListApiResponse<any>>(
      ApiRoutes.users.users.filter({
        offset: offset,
        limit: limit,
      }),
    )
      .then(async (response: ListApiResponse<any>) => {
        setTotalRows((prevTotalRows) =>
          response?.count !== undefined
            ? response?.count
            : prevTotalRows
              ? prevTotalRows
              : 0,
        );
        setUsersData(response.results);
      })
      .catch((error) => {
        setFeedbackState({
          open: true,
          message: `${t("manage_users.failed_to_get_users")}: ${error.message}`,
          severity: "error",
        });
      });
  };

  useEffect(() => {
    loadData();
  }, [offset, limit]);

  const handleAddUserClick = () => {
    loadData();
  };

  const handleEditClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any,
  ) => {
    e.stopPropagation();
    setRowModesModel({
      ...rowModesModel,
      [row.id]: { mode: GridRowModes.Edit },
    });
  };

  const handleDeleteClick = (deleteRowId: number | undefined) => {
    if(deleteRowId === undefined) return;
    ApiDelete(ApiRoutes.users.users.single(deleteRowId))
      .then(async () => {
        setFeedbackState({
          open: true,
          message: `${t("manage_users.success_delete_user")}`,
          severity: "success",
        });
        setUsersData(usersData.filter((row) => row.id !== deleteRowId));
      })
      .catch((error) => {
        setFeedbackState({
          open: true,
          message: `${t("manage_users.failed_to_delete_user")}: ${error.message}`,
          severity: "error",
        });
      });
  };

  const handleDeleteMultipleClick = (
  ) => {
    const selectedIDs = new Set(rowSelectionModel);
    rowSelectionModel.forEach((id) => {
      ApiDelete(ApiRoutes.users.users.single(Number(id)))
        .then(async () => {
          setFeedbackState({
            open: true,
            message: `${t("manage_users.success_delete_user")}`,
            severity: "success",
          });
        })
        .catch((error) => {
          setFeedbackState({
            open: true,
            message: `${t("manage_users.failed_to_delete_user")}: ${error.message}`,
            severity: "error",
          });
        });
    });
    setUsersData(usersData.filter((row) => !selectedIDs.has(row.id)));
  };

  const handlePaginationChange = (model: GridPaginationModel) => {
    const page = model.page;
    const limit = model.pageSize;

    setOffset(page * limit);
    setLimit(limit);
  };

  const handleSelectionChange = (model: GridRowSelectionModel) => {
    const selectedIDs = new Set(model);
    const selectedRows = usersData.filter((row) => selectedIDs.has(row.id));
    setMultipleDeleteUsers(selectedRows);
    setRowSelectionModel(selectedRows.map((row) => row.id));
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = usersData.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setUsersData(usersData.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel, originalRow: GridRowModel) => {
    const newRowWithIsActive = {
      ...newRow,
      is_active: newRow.is_active === "Activated" ? true : false,
      roles: newRow.role === "Admin" ? UserRoles.Admin : newRow.role === "Manager" ? UserRoles.Manager : UserRoles.Viewer
    };
    const updatedRow = { ...newRowWithIsActive, isNew: false };
    ApiPut(ApiRoutes.users.users.single(newRow.id), newRowWithIsActive)
      .then(async (response) => {
        setFeedbackState({
          open: true,
          message: `${t("manage_users.success_update_user")}`,
          severity: "success",
        });
        setUsersData(
          usersData.map((row) => (row.id === newRow.id ? updatedRow : row)),
        );
      })
      .catch((error) => {
        setFeedbackState({
          open: true,
          message: `${t("manage_users.failed_to_update_user")}: ${error.message}`,
          severity: "error",
        });
        return originalRow;
      });
    return updatedRow;
  };

  const handleProcessRowUpdateError = (error: Error) => {
    setFeedbackState({
      open: true,
      message: `${t("manage_users.failed_to_update_user")}: ${error.message}`,
      severity: "error",
    });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
      editable: true,
    },
    {
      field: "role",
      headerName: "User Type",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Admin", "Manager", "Viewer"]
    },
    {
      field: "is_active",
      headerName: "Account Status",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Activated", "Deactivated"],
      valueGetter: (value: boolean) => {
        return value === true ? "Activated" : "Deactivated";
      },
    },
    {
      field: "edit",
      headerName: "Edit User",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`save-${params.row.id}`}
              icon={<SaveIcon color="success" />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(params.row.id)}
              data-testid={`save-button-${params.row.id}`}
            />,
            <GridActionsCellItem
              key={`cancel-${params.row.id}`}
              icon={<CancelIcon color="error" />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.row.id)}
              color="inherit"
              data-testid={`cancel-button-${params.row.id}`}
            />,
          ];
        }

        return (
          <IconButton
            data-testid={`edit-button-${params.row.id}`}
            aria-label="edit"
            size="medium"
            onClick={(e) => handleEditClick(e, params.row)}
            sx={{
              border: "1px solid rgba(46, 68, 130, 1)",
              borderRadius: "4px",
              color: "rgba(46, 68, 130, 1)"
            }}
          >
            <BorderColorOutlinedIcon fontSize="inherit" />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete User",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <DeleteConfirmationDialog
            open={openDeleteDialog}
            message={t('manage_users.delete_single')}
            confirmText={t('manage_users.delete_single_button')}
            testId={`dialogConfirm-${params.row.id}`}
            onClose={value => {
              setOpenDeleteDialog(false);
              if (value) {
                handleDeleteClick(rowToBeDeleted);
              }
            }}
          >
            <IconButton
              data-testid={`delete-button-${params.row.id}`}
              color="error"
              aria-label="delete"
              size="medium"
              onClick={() => {
                setRowToBeDeleted(params.row.id);
                setOpenDeleteDialog(true);
              }}
              sx={{
                border: '1px solid rgba(187, 31, 31, 1)',
                borderRadius: '4px',
                color: 'rgba(187, 31, 31, 1)'
              }}
            >
              <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
          </DeleteConfirmationDialog>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <DataTable
          rows={usersData}
          columns={columns}
          onPaginationChange={handlePaginationChange}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={rowSelectionModel}
          onRowModesModelChange={handleRowModesModelChange}
          rowModesModel={rowModesModel}
          setRowModesModel={setRowModesModel}
          processRowUpdate={processRowUpdate}
          processRowUpdateError={handleProcessRowUpdateError}
          totalRows={totalRows}
          checkboxSelection={true}
          showExport={true}
          showSearch={true}
        >
          <Box
            sx={{
              display: "flex",
              padding: "5px 0",
              backgroundColor: (theme) => theme.palette.primary.main,
              borderRadius: "8px 8px 0 0",
              borderBottom: (theme) => `1px solid ${theme.palette.grey}`,
            }}
          >
            <Box sx={{ flexGrow: "1", display: "flex" }}>
              <Box
                sx={{
                  paddingLeft: "10px",
                  // paddingTop: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PersonOutlineIcon fontSize="small" sx={{ color: "#FEFEFE" }} />
              </Box>
              <Typography
                variant="h6"
                sx={{
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  color: "#FEFEFE",
                  marginRight: "auto",
                }}
              >
                {t("manage_users.title")}
              </Typography>
              <AddUser onAddUserClick={handleAddUserClick} />
            </Box>
          </Box>
        </DataTable>
        <DeleteConfirmationDialog
          open={openDeleteMultipleDialog}
          message={t("manage_users.delete_multiple")}
          confirmText={t("manage_users.delete_multiple_button")}
          users={multipleDeleteUsers}
          onClose={(value) => {
            setOpenDeleteMultipleDialog(false);
            if (value) {
              handleDeleteMultipleClick();
            }
          }}
        >
          <Button
            data-testid={"delete-multiple-button"}
            variant="contained"
            disabled={rowSelectionModel.length >= 1 ? false : true}
            onClick={() => setOpenDeleteMultipleDialog(true)}
            startIcon={<DeleteOutlineOutlinedIcon />}
            sx={{marginTop: "10px"}}
          >
            {t("manage_users.delete_multiple_button")}
          </Button>
        </DeleteConfirmationDialog>
      </Grid>
    </Grid>
  );
}

export function ManageUsers(): JSX.Element {
  return (
    <FeedbackContextProvider>
      <ManageUsersContent />
    </FeedbackContextProvider>
  );
}
