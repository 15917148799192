import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export type ViewActionButtonProps = {
  width?: number;
  url: string;
  name: string;
};

export function ViewActionButton({
  width,
  url,
  name,
}: ViewActionButtonProps): JSX.Element {

  return (
    <Grid lg={width ? width : 3} sx={{ display: 'flex', justifyContent: 'end', gap: '5px' }}>
      <Link
        style={{ textDecoration: 'none' }}
        to={url}
      >
        <Button
          variant="contained"
          color="inherit"
          disableElevation
          sx={{
            backgroundColor: '#fff',
            color: theme => theme.colors.button.primary,
            border: theme => `1px solid ${theme.colors.button.primary}`,
            textTransform: 'none',
            borderRadius: '6px'
          }}
        >
          {name}
        </Button>
      </Link>
    </Grid>

  );
}
